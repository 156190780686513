import { DashboardApi, SuppliersReport } from '../../../Api';
import { dashboardFormatDate } from '../../../Charts/Chart/Chart.utils';
import { SeriesColumnOptions } from 'highcharts';
import { DashboardReportService } from '../../DashboardReportService';
import { createDashboardReportService } from '../../createDashboardReportService';
import { DashboardDataPayload } from '../../../Api/DashboardDataPayload';

export class SuppliersReportService implements DashboardReportService<SuppliersReport, SeriesColumnOptions['data']> {
    private api = new DashboardApi();

    get storedProcedure() {
        return 'SuppliersSendingDashboardsReport' as const;
    }

    async fetchReport(settings: DashboardDataPayload) {
        const res = await this.api.getDashboardReportData<SuppliersReport>(settings, this.storedProcedure);
        return res.data;
    }

    get nullifyDataIfEmptyObj() {
        return {
            optIn: true,
            xAxisPropertyKey: 'Date' as const,
        };
    }

    transformVMToChartData(vm: SuppliersReport['viewModel']) {
        return vm.map((p) => ({
            y: p.ActiveSupplierCount,
            name: dashboardFormatDate(p.Date),
            label: dashboardFormatDate(p.Date),
        }));
    }

    async exportCSV(settings: DashboardDataPayload, storedProcedure?: string) {
        return this.api.exportDashboardReport(settings, storedProcedure ?? this.storedProcedure);
    }
}

export const useSuppliersReportService = createDashboardReportService(SuppliersReportService);
