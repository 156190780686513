import { ISearchRequest, SearchCriteriaError } from '../../reusableFeatures';
import { backendServices, portalUserService } from '../../services';

import { BusinessDocType } from '../../types';
import { DocumentSearchUtil, isValidDateRange } from '../../legacy/transcepta-common';
import { shouldShowDateRangeError } from './shouldShowDateRangeError';
import { CREATED_TIME_FIELD_KEY, includePartialMatchesDefaultFieldConfig } from './getSearchFormFieldColumns';
import { SUFFIX_FOR_DUPES } from './getGridColumns';

type Option = {
    value: string | number | boolean;
};

type SearchFilterValue = {
    type: string;
    name: string;
    value: string | number | Option | Option[];
};

function getDynamicParams(config: backendServices.ViewModels.SearchFilter): Record<string, boolean> {
    const { $filter, ...params } = config;

    /**
     * All the entries in {params} should be [string, string] already, but TS can't infer it from the type of {SearchFilter}
     */
    const parsedParams = Object.entries(params).reduce<Record<string, boolean>>((record, [key, value]) => {
        if (value === 'true') {
            record[key] = true;
        } else if (value === 'false') {
            record[key] = false;
        }

        return record;
    }, {});

    return parsedParams;
}

function transformFilterValuesToLegacySearchParams({
    filterValues,
    SearchCriteria,
}: {
    filterValues: FilterValues;
    SearchCriteria: backendServices.ViewModels.SearchCriteria[];
}) {
    const includePartialMatchesFieldKey = includePartialMatchesDefaultFieldConfig.dataBindingKey;

    const searchParams = Object.entries(filterValues).reduce((params, [fieldName, value]) => {
        if (fieldName === includePartialMatchesFieldKey) {
            return params;
        }

        const fieldConfig = SearchCriteria.find((field) => field.FieldName === fieldName);

        /**
         * This is unlikely to happen, but I've already seen it twice and would like to avoid app crashes.
         * 1. There are some tests that are using an incorrect search configuration in parking lot
         * that is missing the "Viewing" search form field.
         * 2. The search page template picks up any property you push into the history state with history.pushState() and it
         * returns it as part of the filterValues.
         * 3. This utility is now used for transactions/document search pages where "Viewing" does not exist.
         */
        if (typeof fieldConfig === 'undefined') {
            return params;
        }

        switch (fieldConfig.Type) {
            case 'Select': {
                const optionSelected = (() => {
                    if (Array.isArray(value)) {
                        return value.map((v) => ({
                            value: v,
                        }));
                    }

                    return { value } as Option;
                })();

                params[fieldName] = {
                    type: fieldConfig.Type,
                    name: fieldName,
                    value: optionSelected,
                };
                break;
            }
            case 'NumericRange': {
                const valueAsString = (() => {
                    if (!Array.isArray(value)) {
                        return '';
                    }

                    const [start, end] = value;

                    return `${typeof start === 'number' ? start : ''}/${typeof end === 'number' ? end : ''}`;
                })();

                params[fieldName] = {
                    type: fieldConfig.Type,
                    name: fieldName,
                    value: valueAsString,
                };
                break;
            }
            default: {
                params[fieldName] = {
                    type: fieldConfig.Type,
                    name: fieldName,
                    value: value as string,
                };
                break;
            }
        }

        return params;
    }, {} as Record<string, SearchFilterValue>);

    return searchParams;
}

export type FilterValues = Record<
    | keyof backendServices.ViewModels.DocumentSearchViewModel
    | typeof includePartialMatchesDefaultFieldConfig['dataBindingKey']
    | 'user',
    unknown
>;

/**
 * * It will throw a SearchCriteriaError if {filterValues} isn't valid
 */
export function validateFilterValues({
    filterValues,
    searchConfiguration,
}: {
    filterValues: FilterValues;
    searchConfiguration: backendServices.ViewModels.DocumentSearchConfigurationViewModel;
}) {
    // User selection
    const includePartialMatchesFieldKey = includePartialMatchesDefaultFieldConfig.dataBindingKey;
    const includePartialMatches = !!filterValues[includePartialMatchesFieldKey];

    const searchParams = transformFilterValuesToLegacySearchParams({
        filterValues,
        SearchCriteria: searchConfiguration.SearchConfiguration.SearchCriteria,
    });

    const containsSearchInfo = DocumentSearchUtil.getContainsSearchInfo(
        includePartialMatches,
        searchConfiguration.SearchConfiguration.SearchFilter,
        searchParams
    );

    if (isValidDateRange(filterValues[CREATED_TIME_FIELD_KEY])) {
        const [startDate, endDate] = filterValues[CREATED_TIME_FIELD_KEY];
        if (shouldShowDateRangeError(startDate, endDate, containsSearchInfo)) {
            throw new SearchCriteriaError('Searches are limited to 6 months to ensure optimal performance');
        }
    }
}

function getDefaultValueForUserFilter(
    users: Pick<backendServices.ViewModels.UserViewModel, 'ID' | 'Name' | 'SubstituteUserActive' | 'SubstituteUserId'>[]
) {
    const currentUserId = portalUserService.getCurrentUser('mustBeLoggedIn').id;

    const idsOfUsersBeingSubstituted = users
        .filter((user) => !!user.SubstituteUserActive && user.SubstituteUserId === currentUserId)
        .map(({ ID }) => ID);

    const filterValues = new Set([currentUserId, ...idsOfUsersBeingSubstituted]);

    return Array.from(filterValues).toString();
}

export function getFilterValuesWithRightValueForUserFilter(
    filterValues: FilterValues,
    searchConfiguration: backendServices.ViewModels.DocumentSearchConfigurationViewModel,
    users: Pick<backendServices.ViewModels.UserViewModel, 'ID' | 'Name' | 'SubstituteUserActive' | 'SubstituteUserId'>[]
): FilterValues {
    /**
     * This is the default if the user hasn't selected anything or if they picked the empty option (Label: "")
     * TODO: Revisit this logic in 5a, I'm not sure that setting a default value other than the one configured for the empty option is correct.
     */
    if (!filterValues.user) {
        return { ...filterValues, user: getDefaultValueForUserFilter(users) };
    }

    const {
        SearchConfiguration: { SearchCriteria },
    } = searchConfiguration;

    const field = SearchCriteria.find((f) => f.FieldName === 'user' && f.Type === 'Select');

    if (field?.Type !== 'Select') {
        return filterValues;
    }

    const selectedOption = field.Options.find((opt) => opt.Label === filterValues.user);

    return { ...filterValues, user: selectedOption?.Value };
}

export function getSearchFilterAndParams(
    request: ISearchRequest<FilterValues>,
    searchConfiguration: backendServices.ViewModels.DocumentSearchConfigurationViewModel,
    businessDocType: BusinessDocType,
    users: Pick<backendServices.ViewModels.UserViewModel, 'ID' | 'Name' | 'SubstituteUserActive' | 'SubstituteUserId'>[]
): {
    $filter: string;
    dynamicParams: Record<string, string | boolean>;
} {
    const {
        searchQuery: { state: baseFilterValues },
    } = request;

    const filterValues = getFilterValuesWithRightValueForUserFilter(baseFilterValues, searchConfiguration, users);

    const {
        SearchConfiguration: { SearchCriteria, SearchFilter },
    } = searchConfiguration;

    validateFilterValues({ filterValues, searchConfiguration });

    const searchParams = transformFilterValuesToLegacySearchParams({
        SearchCriteria,
        filterValues,
    });

    // User selection
    const includePartialMatchesFieldKey = includePartialMatchesDefaultFieldConfig.dataBindingKey;
    const includePartialMatches = !!filterValues[includePartialMatchesFieldKey];

    const { $filter, additionalParameters } = DocumentSearchUtil.getSearchFilter(
        businessDocType,
        searchParams,
        SearchFilter,
        includePartialMatches
    );

    const dynamicParams = getDynamicParams(SearchFilter);

    return {
        $filter,
        dynamicParams: { ...additionalParameters, ...dynamicParams },
    };
}

/**
 * This function mutates the searchRequest object to remove the suffix from the fields in the sort model
 *
 * @param searchRequest the search request object from SearchPageTemplate
 */
export function removeSuffixForDupesFromSearchRequest(searchRequest: ISearchRequest<FilterValues>): void {
    const newSortModel = searchRequest.sort.map((item) => ({
        ...item,
        field: item.field.replaceAll(SUFFIX_FOR_DUPES, ''),
    }));

    searchRequest.sort = newSortModel;
}

export enum DocumentSearchConfigurationPage {
    DOCUMENTS = 1,
    PARKING_LOT = 3,
    INVOICES_REJECTED = 4,
    INVOICES_DELIVERED = 5,
}
