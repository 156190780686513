import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import LogRocket from 'logrocket';
import { calculateResponsiveState, createResponsiveStateReducer } from 'redux-responsive';
import { createBrowserHistory as createHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import AppearanceReducer from './AppearanceReducer';
import * as userlogin from './userlogin';
import * as manageUsers from './profile/manageUsers';
import * as changePassword from './profile/changePassword';
import * as alerts from './alerts';
import documentReducer from './document/document';
import * as documentSearch from './document/documentSearch';
import * as documentLayout from './document/documentLayout';
import * as menuBages from './menuBages';
import documentListReducerCreator from './documentsList';
import customerReducer from './CustomerReducer';
import documentCreation from './documentCreation';
import * as company from './company/companyReducer';
import * as user from './user';
import * as simDocumentTypes from './simDocumentTypes';
import * as contact from './contact';

import * as serviceAgreement from './serviceAgreement';
import { noticePage } from './noticePage';
import profilesReducer from './profile/ProfilesReducer';
import * as helpResource from './helpResource';
import * as reports from './reports';
import * as notifications from './userNotifications';

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const history = createHistory();
const routerHistoryMiddleware = routerMiddleware(history);
const store = createStore(
    combineReducers({
        appearance: AppearanceReducer,
        ...userlogin,
        ...manageUsers,
        ...alerts,
        ...changePassword,
        profiles: profilesReducer,
        documentCreation,
        ...serviceAgreement,
        invoices: documentListReducerCreator('INVOICES'),
        purchaseOrders: documentListReducerCreator('PURCHASE_ORDERS'),
        purchaseOrderAcknowledgements: documentListReducerCreator('PURCHASE_ORDER_ACKN'),
        asns: documentListReducerCreator('ASNS'),
        parkingLot: documentListReducerCreator('PARKING_LOT'),
        inventoryAdvices: documentListReducerCreator('INVENTORY_ADVICES'),
        consumptionAdvices: documentListReducerCreator('CONSUMPTION_ADVICES'),
        receivingAdvices: documentListReducerCreator('RECEIVING_ADVICES'),
        sims: documentListReducerCreator('SIMS'),
        document: documentReducer,
        ...documentSearch,
        ...documentLayout,
        ...menuBages,
        selectCustomer: customerReducer,
        router: connectRouter(history),
        browser: createResponsiveStateReducer({
            small: 768,
            medium: 1024,
            large: 9000,
        }),
        noticePage,
        ...company,
        ...helpResource,
        ...user,
        ...reports,
        ...simDocumentTypes,
        ...notifications,
        ...contact,
    }),
    composeEnhancers(applyMiddleware(routerHistoryMiddleware, thunkMiddleware, LogRocket.reduxMiddleware()))
);
// setup responsive reducer
store.dispatch(calculateResponsiveState(window));
window.addEventListener('resize', () => store.dispatch(calculateResponsiveState(window)));

export default store;
