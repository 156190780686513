import { DashboardApi, InvoicesRejected } from '../../../Api';
import { SeriesColumnOptions } from 'highcharts';
import { DashboardReportService } from '../../DashboardReportService';
import { createDashboardReportService } from '../../createDashboardReportService';
import { DashboardDataPayload } from '../../../Api/DashboardDataPayload';

export class RejectionReasonReportService
    implements DashboardReportService<InvoicesRejected, SeriesColumnOptions['data']>
{
    private api = new DashboardApi();

    get storedProcedure() {
        return 'RejectionReasonDashboardReport' as const;
    }

    async fetchReport(settings: DashboardDataPayload) {
        const res = await this.api.getDashboardReportData<InvoicesRejected>(settings, this.storedProcedure);
        return res.data;
    }

    get nullifyDataIfEmptyObj() {
        return {
            optIn: true,
            xAxisPropertyKey: undefined,
        };
    }

    transformVMToChartData(vm: InvoicesRejected['viewModel']) {
        return vm.map((p) => ({
            y: p.NumRejections,
            name: `${p.Label}`,
            label: `${p.NumRejections?.toLocaleString('en-US')} (${p.RejectionPercent}%)`,
        }));
    }
}

export const useRejectionReasonReportService = createDashboardReportService(RejectionReasonReportService);
