import { FC } from 'react';
import {
    Box,
    List,
    ListItem,
    ListItemButton,
    Typography,
    useMediaQuery,
    useTheme,
    CircularProgress,
    IconButton,
    Stack,
} from '@mui/material';
import { Sync } from '@mui/icons-material';
import { NavLink } from 'react-router-dom';
import { disableLeftNavLink, menuVisibilityMapper } from './portalLeftNav';
import { useIsSelected } from './useIsSelected';
import { useCurrentUserCan } from '../../services';
import { useHistory } from 'react-router';

const SyncButton = ({
    isSyncing,
    isHighlighted,
    onClick,
}: {
    isSyncing: boolean;
    isHighlighted: boolean;
    onClick: () => void;
}) => {
    return (
        <IconButton
            disabled={isSyncing}
            onClick={(event) => {
                event.preventDefault();

                onClick();
            }}
            edge="end"
        >
            {isSyncing ? (
                <CircularProgress
                    size="15px"
                    sx={{
                        color: 'white',
                    }}
                />
            ) : (
                <Sync
                    sx={{
                        pointerEvents: 'auto',
                        color: isHighlighted ? 'white' : 'black',
                    }}
                />
            )}
        </IconButton>
    );
};

interface INavigationDrawerProps {
    menuSettings: any;

    syncAllWorkflows?: {
        onClick: () => void;
        isSyncing: boolean;
    };
}

export const LeftNav: FC<INavigationDrawerProps> = ({ menuSettings, syncAllWorkflows }) => {
    const isDashboardEnabled = useCurrentUserCan('Dashboard.DisplayNewDashboard');
    const items = menuVisibilityMapper(menuSettings, isDashboardEnabled);
    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
    const isSelected = useIsSelected();
    const history = useHistory();

    if (isMdDown) {
        return null;
    }

    if (!items) {
        return null;
    }

    return (
        <Box sx={{ minWidth: 245, bgcolor: 'background.paper' }}>
            <List sx={{ height: '100%', p: 0 }}>
                {items?.map((menuItem, index) => {
                    const withSyncButton = menuItem.path.includes('/BuyerPortal/parking-lot') && index === 0;
                    const itemIsSelected = isSelected(menuItem.path);
                    return (
                        <ListItem
                            key={`${menuItem.name}-${menuItem.path}`}
                            sx={{ p: 0 }}
                            data-testid={`left-nav-item-${menuItem.name}`}
                        >
                            <ListItemButton
                                component={NavLink}
                                to={menuItem.path}
                                sx={
                                    itemIsSelected
                                        ? {
                                              backgroundColor: '#0D629E',
                                              color: 'white',
                                              '&:hover': {
                                                  backgroundColor: '#06314F',
                                                  color: 'white',
                                              },
                                              boxShadow: 'inset -6px 0 #107AC6',
                                              pointerEvents:
                                                  disableLeftNavLink(history.location.pathname) ||
                                                  items.some(({ path }) => path === history.location.pathname)
                                                      ? 'none'
                                                      : undefined,
                                          }
                                        : {
                                              '&:hover': {
                                                  backgroundColor: 'primary.100',
                                              },
                                          }
                                }
                            >
                                <Stack direction="row" justifyContent="space-between" width="100%">
                                    <Typography variant="button" sx={{ py: 1, textTransform: 'capitalize' }}>
                                        {menuItem.name} {menuItem.count ? `(${menuItem.count})` : ''}
                                    </Typography>
                                    {syncAllWorkflows && withSyncButton && (
                                        <SyncButton
                                            isSyncing={syncAllWorkflows.isSyncing}
                                            isHighlighted={itemIsSelected}
                                            onClick={() => {
                                                syncAllWorkflows.onClick();
                                            }}
                                        />
                                    )}
                                </Stack>
                            </ListItemButton>
                        </ListItem>
                    );
                })}
            </List>
        </Box>
    );
};
