import { ExecutionService } from '../CustomJSRuleEngineV2';

type ValidationResult = ReturnType<
    Awaited<
        ReturnType<typeof ExecutionService.DPSValidations.executeClientValidations>
    >['transformToErrorAndWarningLists']
>;

const formatResults = (results: ValidationResult['errors'] | ValidationResult['warnings']) =>
    results
        .map(({ defaultVFT }) => {
            if (!defaultVFT?.titleText) {
                return null;
            }

            const message = defaultVFT.detailText
                ? `${defaultVFT.titleText}: ${defaultVFT.detailText}`
                : defaultVFT.titleText;

            return message;
        })
        .filter((msg): msg is string => msg !== null);

export function formatValidationResults(results: ValidationResult | undefined) {
    if (results == null) {
        return { errorMessages: [], warningMessages: [] };
    }

    const errorMessages = formatResults(results.errors);

    const warningMessages = formatResults(results.warnings);

    return { errorMessages, warningMessages };
}
