import { BusinessDocType } from '../../../legacy/transcepta-types';
import { DocumentValidationEvent } from '../proservContractTypes';
import { DocumentEditValidationConfiguration, DocumentEditValidationState } from '../types/private';
import { createPluginServices } from '../PluginHost';
import { DynamicErrorRenderer } from '../ErrorRenderer';
import DropdownOptionsRepository from '../PluginHost/services/dropdownOptionsRepository';
import { ascendingOrderByKey } from '../../../utils/sorting';

const pluginServices = createPluginServices('', new DropdownOptionsRepository());

export function projectDocumentInfo(documentInfo: DocumentEditValidationState) {
    switch (documentInfo.type) {
        case 'WebEntryParams':
            return {
                type: 'WebEntry',
            } as const;
        case 'UserWorkflowActivityParams':
            return {
                type: 'UserWorkflowActivity',
                workflowTaskId: documentInfo.workflowTaskId,
            } as const;
        default:
            throw new Error('Unknown document info type.');
    }
}

export type ProjectedDocumentInfo = ReturnType<typeof projectDocumentInfo>;

function transformEditModeToParams(documentInfo: ProjectedDocumentInfo) {
    switch (documentInfo.type) {
        case 'WebEntry':
            return { isWebEntry: true };
        case 'UserWorkflowActivity':
            return { workflowTaskId: documentInfo.workflowTaskId };
        default:
            throw new Error('Unknown document info type.');
    }
}

interface IBuyerCustomJSRule {
    Name: string;
    JavaScriptFilePath: string;
}

interface IBuyerCustomJSRuleMappingXref {
    BuyerCustomJSRule: IBuyerCustomJSRule;
    Ordinal: number;
}

interface IBuyerCustomJSRuleMapping {
    FocusLossFieldNames: string;
    RunOnWebEntry: boolean;
    RunOnWorkflowFocusLoss: boolean;
    RunOnWorkflowReassign: boolean;
    RunOnWorkflowSave: boolean;
    RunOnWorkflowSubmit: boolean;
    RunOnWorkflowLoad: boolean;
    BuyerCustomJSRuleMappingXrefs: IBuyerCustomJSRuleMappingXref[];
}

function extractEvent(isEnabled: boolean, name: DocumentValidationEvent['type']) {
    if (isEnabled) {
        return [name];
    }
    return [];
}

function transformConfiguration(
    mapping: IBuyerCustomJSRuleMapping,
    documentInfoType: ProjectedDocumentInfo['type'],
    buyerCompanyId: number,
    businessDocType: BusinessDocType
): DocumentEditValidationConfiguration {
    return {
        fieldNamesToValidateOnFocusLoss:
            mapping.FocusLossFieldNames?.split(',')
                .map((x) => x.trim())
                .filter(Boolean) || [],
        validationFunctions: mapping.BuyerCustomJSRuleMappingXrefs.sort(ascendingOrderByKey('Ordinal')).map((x) => ({
            name: x.BuyerCustomJSRule.Name,
            src: x.BuyerCustomJSRule.JavaScriptFilePath,
        })),
        eventsToValidateOn: [
            ...extractEvent(mapping.RunOnWebEntry, 'WebEntrySaveAndPreviewEvent'),
            ...extractEvent(mapping.RunOnWorkflowFocusLoss, 'FocusLossEvent'),
            ...extractEvent(mapping.RunOnWorkflowReassign, 'WorkflowReassignEvent'),
            ...extractEvent(mapping.RunOnWorkflowSave, 'WorkflowSaveEvent'),
            ...extractEvent(mapping.RunOnWorkflowSubmit, 'WorkflowSubmitEvent'),
            ...extractEvent(
                mapping.RunOnWorkflowLoad && documentInfoType === 'UserWorkflowActivity',
                'WorkflowLoadEvent'
            ),
        ],
        errorRenderer: new DynamicErrorRenderer(buyerCompanyId, businessDocType),
    };
}

export async function fetchConfiguration(
    buyerCompanyId: number,
    businessDocType: BusinessDocType,
    documentInfo: ProjectedDocumentInfo
) {
    const resp = await pluginServices.api().get<IBuyerCustomJSRuleMapping[]>('/BuyerCustomJSRuleMapping', {
        params: {
            buyerCompanyId,
            businessDocType,
            ...transformEditModeToParams(documentInfo),
        },
    });

    if (resp.data.length === 0) {
        return null;
    }

    // Note: The API is supposed to only return 1 configuration, but still returns an array.
    // We just take the first element as this is equivalent functionally as long as the API
    // is returning the correct single element.
    return transformConfiguration(resp.data[0], documentInfo.type, buyerCompanyId, businessDocType);
}
