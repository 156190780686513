import { DashboardApi, SpendPayments } from '../../../Api';
import { SeriesColumnOptions } from 'highcharts';
import { DashboardReportService } from '../../DashboardReportService';
import { createDashboardReportService } from '../../createDashboardReportService';
import { DashboardDataPayload } from '../../../Api/DashboardDataPayload';

export class SpendPaymentsReportService implements DashboardReportService<SpendPayments, SeriesColumnOptions['data']> {
    private api = new DashboardApi();

    get storedProcedure() {
        return 'InvoicePaymentsDashboardReport' as const;
    }

    async fetchReport(settings: DashboardDataPayload) {
        const res = await this.api.getDashboardReportData<SpendPayments>(settings, this.storedProcedure);
        return res.data;
    }

    get nullifyDataIfEmptyObj() {
        return {
            optIn: true,
            xAxisPropertyKey: 'PaymentStatus' as const,
        };
    }

    transformVMToChartData(vm: SpendPayments['viewModel']) {
        return vm.map((p) => ({
            y: p.Count,
            name: p.PaymentStatus,
            label: p.PaymentStatus,
        }));
    }

    async exportCSV(settings: DashboardDataPayload) {
        return this.api.exportDashboardReport(settings, this.storedProcedure);
    }
}

export const useSpendPaymentsReportService = createDashboardReportService(SpendPaymentsReportService);
