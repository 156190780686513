import { BusinessDocType } from '../../types';

export const subNavPaths = {
    dashboard: [
        { path: '/BuyerPortal/dashboard/overview', name: 'Overview' },
        { path: '/BuyerPortal/dashboard/straight-through-processing', name: 'Straight-Through Processing' },
        { path: '/BuyerPortal/dashboard/suppliers', name: 'Suppliers' },
        { path: '/BuyerPortal/dashboard/invoices', name: 'Invoices' },
        { path: '/BuyerPortal/dashboard/spend', name: 'Spend' },
    ],
    transactions: [
        { path: '/BuyerPortal/transactions/asns', name: 'ASNs', docType: BusinessDocType.ASNGeneric },
        {
            path: '/BuyerPortal/transactions/inventory',
            name: 'Inventory Advices',
            docType: BusinessDocType.InventoryAdvice,
        },
        { path: '/BuyerPortal/transactions/invoices', name: 'Invoices', docType: BusinessDocType.Invoice },
        {
            path: '/BuyerPortal/transactions/acklns',
            name: 'PO Acknowledgments',
            docType: BusinessDocType.PurchaseOrderAcknowledgement,
        },
        { path: '/BuyerPortal/transactions/pos', name: 'Purchase Orders', docType: BusinessDocType.PurchaseOrder },
        {
            path: '/BuyerPortal/transactions/consumption',
            name: 'Consumption Advices',
            docType: BusinessDocType.ConsumptionAdvice,
        },
        {
            path: '/BuyerPortal/transactions/receiving',
            name: 'Receiving Advices',
            docType: BusinessDocType.ReceivingAdvice,
        },
        {
            path: '/BuyerPortal/transactions/sim-documents',
            name: 'SIM Documents',
            docType: BusinessDocType.SIMDocument,
        },
    ],
    'parking-lot': [
        { path: 'all-workflows', name: 'All Workflows', workflowTemplateUI: 'All Workflows' },
        { path: 'invoice-reconcile', name: 'Nonstock Invoices', workflowTemplateUI: 'Invoice Reconcile' },
        { path: 'counter-pos', name: 'Counter POS', workflowTemplateUI: 'Counter POs' },
        { path: 'invoice-coding', name: 'Invoice Coding', workflowTemplateUI: 'Invoice Coding' },
        { path: 'document-approval', name: 'Document Approval', workflowTemplateUI: 'Document Approval' },
        { path: 'non-inventory', name: 'GL Coding', workflowTemplateUI: 'Non-Inventory' },
        { path: 'non-inventory-release', name: 'GL Coding Release', workflowTemplateUI: 'Non-Inventory Rel.' },
        { path: 'parked-documents', name: 'Parked Documents', workflowTemplateUI: 'Parked Documents' },
        { path: 'invoice-approval', name: 'PO Line Nums', workflowTemplateUI: 'Invoice Approval' },
        {
            path: 'create',
            name: 'New Document',
            workflowTemplateUI: 'Document Approval',
            showLeftNavInParkingLot: false,
        },
    ],
    'dynamic-discounting': [
        { path: '/BuyerPortal/dynamic-discounting/new', name: 'Create Campaign' },
        { path: '/BuyerPortal/dynamic-discounting/reports', name: 'Reporting' },
        { path: '/BuyerPortal/dynamic-discounting/users', name: 'Manage Users' },
        { path: '/BuyerPortal/dynamic-discounting/settings', name: 'Settings' },
    ],
    documents: [
        { path: '/SupplierPortal/documents/asns', name: 'ASNs', docType: BusinessDocType.ASNGeneric },
        {
            path: '/SupplierPortal/documents/consumption-advices',
            name: 'Consumption Advices',
            docType: BusinessDocType.ConsumptionAdvice,
        },
        {
            path: '/SupplierPortal/documents/inventory-advices',
            name: 'Inventory Advices',
            docType: BusinessDocType.InventoryAdvice,
        },
        { path: '/SupplierPortal/documents/invoices', name: 'Invoices', docType: BusinessDocType.Invoice },
        {
            path: '/SupplierPortal/documents/purchase-orders',
            name: 'Purchase Orders',
            docType: BusinessDocType.PurchaseOrder,
        },
        {
            path: '/SupplierPortal/documents/po-acknowledgments',
            name: 'PO Acknowledgments',
            docType: BusinessDocType.PurchaseOrderAcknowledgement,
        },
        {
            path: '/SupplierPortal/documents/receiving-advices',
            name: 'Receiving Advices',
            docType: BusinessDocType.ReceivingAdvice,
        },
        {
            path: '/SupplierPortal/documents/sim-documents',
            name: 'SIM Documents',
            docType: BusinessDocType.SIMDocument,
        },
    ],
};

export const parseBusinessDocumentVisibility = (menuSettings: any, docType: BusinessDocType) =>
    !!menuSettings?.BusinessDocEnables?.find((item: any) => item.BusinessDocType === docType);

export const menuVisibilityMapper = (menuSettings: any, isDashboardEnabled?: boolean, forcedPathName?: string) => {
    const pathname = forcedPathName ? forcedPathName : window.location.pathname;

    // ! This is a temporary workaround to avoid the horizontal overflow when viewing a document while the left nav is displayed
    const lastSegment = pathname.split('/').at(-1);
    const lastSegmentIsDocId = typeof Number(lastSegment) === 'number' && !isNaN(Number(lastSegment));
    const isNewParkingLotDocumentCreator = !!subNavPaths['parking-lot'].find(
        (subItem) => lastSegment === subItem.path && subItem.showLeftNavInParkingLot === false
    );

    if (pathname.includes('dashboard') && isDashboardEnabled) {
        return subNavPaths.dashboard;
    }

    if (pathname.includes('transactions') && !lastSegmentIsDocId) {
        return subNavPaths.transactions.filter((item) => parseBusinessDocumentVisibility(menuSettings, item.docType));
    }

    if (
        pathname.includes('parking-lot') &&
        !pathname.includes('settings') &&
        !lastSegmentIsDocId &&
        !isNewParkingLotDocumentCreator
    ) {
        let total = 0;

        const enabled = menuSettings.WorkflowsEnabled.map((item: any) => {
            const path = subNavPaths['parking-lot'].find(
                (subItem) => subItem.workflowTemplateUI === item.WorkflowTemplateUI
            );

            if (!item.WorkflowTemplateUI) {
                return undefined;
            }

            total += item.DocumentCount;

            return {
                name: item.ShortName,
                path: `/BuyerPortal/parking-lot/${item.Id}/${path?.path}`,
                count: item.DocumentCount,
            };
        });

        const items = enabled.filter((item: any) => item !== undefined);

        if (items.length > 1 || !items.length) {
            items.unshift({
                name: 'All Workflows',
                path: '/BuyerPortal/parking-lot/all-workflows',
                count: total,
            });
        }

        return items;
    }

    if (pathname.includes('dynamic-discounting')) {
        return subNavPaths['dynamic-discounting'];
    }

    // We need to check for supplier portal because otherwise these items would be added when visiting `/BuyerPortal/parking-lot/${workflowId}/parked-documents/${documentId}`.
    // This will only happen on the parking lot workflow.
    if (pathname.includes('documents') && pathname.includes('SupplierPortal')) {
        return subNavPaths.documents.filter((item) => parseBusinessDocumentVisibility(menuSettings, item.docType));
    }

    return null;
};

export const disableLeftNavLink = (pathname: string) => {
    return [
        '/BuyerPortal/transactions/invoices/DeliveredInvoices',
        '/BuyerPortal/transactions/invoices/RejectedInvoices',
    ].includes(pathname);
};
