import { ReactNode } from 'react';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import clsx from 'clsx';
import { FCNC } from '../FCNC';
import { useGenericPageTemplateStyles } from './useGenericPageTemplateStyles';

export interface IGenericPageTemplateProps {
    testId?: string;
    title?: ReactNode;
    headerContent?: ReactNode;
    body: ReactNode;
}

export const GenericPageTemplate: FCNC<IGenericPageTemplateProps> = ({ title, headerContent, body, testId }) => {
    const classes = useGenericPageTemplateStyles();
    const theme = useTheme();
    const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div className={classes.root} data-testid={testId}>
            <div 
                style={
                    isSmDown
                        ? {
                              paddingRight: '8px',
                              paddingLeft: '8px',
                          }
                        : {}
                }
            className={clsx(classes.body, classes.title)} data-testid={`${testId}-header`}>
                {title && (
                    <Typography component="h2" variant="h5" data-testid={`${testId}-header-title`}>
                        {title}
                    </Typography>
                )}
                {headerContent}
            </div>
            <div data-testid={`${testId}-body`} className={clsx(classes.body, classes.content)}>
                {body}
            </div>
        </div>
    );
};
