import {
    IField,
    createCheckboxField,
    createDateRangePickerField,
    createDropdownField,
    createRangeNumericTextField,
    createTextField,
} from '../../reusableFeatures';
import { backendServices } from '../../services';
import { createSafeHTMLString } from '../../utils';

const INCLUDE_PARTIAL_MATCHES_FIELD_KEY = 'IncludePartialMatches';

export const CREATED_TIME_FIELD_KEY = 'CreatedTime' as const;

export const SENDER_CONFIRMATION_FIELD_KEY = 'SenderConfirmationTime' as const;

export const DISPATCHED_TIME_FIELD_KEY = 'DispatchedTime' as const;

export const includePartialMatchesDefaultFieldConfig = {
    id: INCLUDE_PARTIAL_MATCHES_FIELD_KEY,
    label: 'Include partial matches in search results',
    dataBindingKey: INCLUDE_PARTIAL_MATCHES_FIELD_KEY,
    defaultValue: false,
    description: createSafeHTMLString(
        "<p><i>Standard search</i>: shows documents where the selected field begins with your search term, like specific invoice or vendor numbers</p><p><i>Partial search</i>: shows documents with your search term anywhere in the selected field*</p><p>*Default search range is 90 days, but can be changed to up to 6 months from today's date</p>"
    ),
    visibility: 'AlwaysInvisible',
} as const;

export function getSearchFormFieldColumns(
    configuration: backendServices.ViewModels.DocumentSearchConfigurationViewModel
): IField[][] {
    const {
        SearchConfiguration: { SearchCriteria },
    } = configuration;

    // TS complains that `toSorted` is not a valid method.
    const fields = [...SearchCriteria].sort((a, b) => a.Row - b.Row);

    const result = fields.reduce(
        (columns, field) => {
            const columnIdx = field.Column === 1 ? 0 : 1;

            switch (field.Type) {
                case 'Input': {
                    columns[columnIdx].push(
                        createTextField({
                            id: field.FieldName,
                            label: field.Label,
                            defaultValue: '',
                            dataBindingKey: field.FieldName,
                            description: field.Type,
                        })
                    );
                    break;
                }
                case 'Select': {
                    /**
                     * The user field can have multiple options with the same 'Value',
                     * but the field renderer does not support this scenario.
                     */
                    const isUserField = field.FieldName === 'user';

                    const options = field.Options.map((opt) => ({
                        label: opt.Label,
                        value: isUserField ? opt.Label : opt.Value,
                    }));

                    columns[columnIdx].push(
                        createDropdownField({
                            id: field.FieldName,
                            label: field.Label,
                            multiple: field.Multi,
                            defaultValue: field.Multi ? [] : '',
                            dataBindingKey: field.FieldName,
                            options: options,
                            description: field.Type,
                        })
                    );
                    break;
                }
                case 'DateRange': {
                    columns[columnIdx].push(
                        createDateRangePickerField({
                            id: field.FieldName,
                            label: field.Label,
                            placeholders: [field.PlaceHolder1, field.PlaceHolder2],
                            defaultValue: [null, null],
                            dataBindingKey: field.FieldName,
                            description: field.Type,
                        })
                    );
                    break;
                }
                case 'NumericRange': {
                    columns[columnIdx].push(
                        createRangeNumericTextField({
                            id: field.FieldName,
                            label: field.Label,
                            defaultValue: [null, null],
                            dataBindingKey: field.FieldName,
                            locale: 'en_US',
                            description: field.Type,
                        })
                    );
                    break;
                }
                case 'Checkbox': {
                    columns[columnIdx].push(
                        createCheckboxField({
                            id: field.FieldName,
                            label: field.Label,
                            defaultValue: false,
                            dataBindingKey: field.FieldName,
                            description: field.Type,
                        })
                    );
                    break;
                }
            }

            return columns;
        },
        [new Array<IField>(), new Array<IField>()]
    );

    result[0].push(createCheckboxField(includePartialMatchesDefaultFieldConfig));

    return result;
}
