import { BusinessDocType } from '../../../../types';
import { WorkflowTemplateUIType } from '../../../BusinessDocTypeSearch';
import { ProfileType } from '../../../Profile';
import { EntryConditionLogicType, WorkflowApprovalType, WorkflowResult } from '../../Api/ViewModels';
import { IWorkflowActivity, WorkflowActivityReprocess } from './WorkflowActivity';
import { IWorkflowGroupUser } from './WorkflowGroupUser';

export interface IWorkflow {
    id?: number;
    name: string;
    shortName: string;
    workflowTemplateUI: WorkflowTemplateUIType | null;
    version: string;
    businessDocType: BusinessDocType; // Supported value: Invoice
    businessDocType_US?: string;
    reprocess: IWorkflowReprocess | null; // parsed from XML Data Structure
    profileType: ProfileType; // Supported value: BuyerDestination
    profileType_US?: string;
    profileId: number | null;
    ruleOrdinal: number | null;
    entryConditionLogicType: EntryConditionLogicType; // Supported value: Stored Procedure Name
    entryConditionLogicType_US?: string;
    entryConditionLogic: string;
    entryConditionLogicJavaScriptFilePath: string | null;
    active: boolean;
    customData: IWorkflowCustomData | null; // parsed from XML Data Structure
    approvalType: WorkflowApprovalType | null;
    approvalType_US?: string;
    workflowActivities: IWorkflowActivity[];
    workflowGroupUsers: IWorkflowGroupUser[];
    ruleName: string;
}

export interface IWorkflowReprocess {
    reprocessType: WorkflowActivityReprocess | null;
    poUploadCustomLogic: string | null;
    workflowTaskResult: string;
    workflowTaskCustomExplanation: string;
    workflowInstanceResult: WorkflowResult | null;
    workflowInstanceCustomExplanation: string | null;
    createNewWorkflowInstance: boolean | null;
}

export interface IWorkflowCustomData {
    glCodingOptions: {
        glCodingType: 'Line' | 'Header' | null;
        glCodingSeparator: string;
        glCodingUIType: GLCodingUIType | null;
        glAccountStructure: number | null;
    } | null;
    workflowMessage: string;
    sendRejectionCommentsToOriginator: boolean;
    includeApprovalsInHistory: boolean;
    includeEditsInHistory: boolean;
    includeRestartWorkflowInHistory: boolean;
    includeWorkflowCreatedInHistory: boolean;
    includeWorkflowClosedInHistory: boolean;
    includeWorkflowTaskCreatedInHistory: boolean;
    includeWorkflowTaskClosedInHistory: boolean;
    includeWorkflowSystemChangeInHistory: boolean;
    enableBulkReassignment: boolean;
    enableBulkRejection: boolean;
    enableBulkApproval: boolean;
    enableInvoiceCreation: boolean;
    enableInvoiceCreationOCROption: boolean;
    enableInvoiceCreationAIOption: boolean;
}

export enum GLCodingUIType {
    InlineDropdowns = 1,
    InlineDotNotation = 2,
    InlineWithPopup = 3,
    Popup = 4, // This should be displayed as "Popup Warehouse"
}
