import { FC, ComponentProps } from 'react';
import {
    Box,
    BoxProps,
    FormControl,
    FormHelperText,
    InputLabel,
    Select as MuiSelect,
    SelectChangeEvent,
    SxProps,
    Theme,
    useTheme,
} from '@mui/material';
import { ReadOnly } from '../ReadOnly';
import { useFieldsReadonly } from '../../ui/Form/FormControlContext';
import { renderTooltipLabel, ITooltipIconProps, hideTooltipIconWhenLabelNotFloating } from '../Tooltip';

export interface ISelectProps {
    label?: string;
    labelId?: string;
    id?: string;
    value?: string | number | (string | number)[];
    name?: string;
    onChange?: (event: SelectChangeEvent<string | number | (string | number)[]>) => void;
    readonly?: boolean;
    size?: 'small' | 'medium';
    required?: boolean;
    testId?: string;
    tooltip?: ITooltipIconProps;
    disabled?: boolean;
    renderValue?: ComponentProps<typeof MuiSelect>['renderValue'];
    inputLabelSx?: SxProps<Theme>;
    selectContainerSx?: BoxProps['sx'];
    multiple?: boolean;
    error?: boolean;
    helperText?: string;
}

const Select: FC<ISelectProps> = ({
    label,
    labelId,
    id,
    value,
    name,
    onChange,
    children,
    readonly,
    size,
    required,
    testId,
    tooltip,
    disabled,
    renderValue,
    inputLabelSx,
    multiple,
    selectContainerSx,
    error,
    helperText,
}) => {
    const theme = useTheme();

    const isReadonly = useFieldsReadonly(readonly);
    if (isReadonly) {
        return <ReadOnly testId={testId} label={label} value={Array.isArray(value) ? value.join(', ') : value} />;
    }

    const selectLabel = renderTooltipLabel({ label, tooltip });

    return (
        <Box sx={{ width: 1, ...selectContainerSx }}>
            <FormControl fullWidth={true} sx={hideTooltipIconWhenLabelNotFloating} error={error}>
                <InputLabel sx={{ ...inputLabelSx }} id={labelId}>
                    {selectLabel}
                </InputLabel>
                <MuiSelect
                    data-testid={testId}
                    variant="outlined"
                    label={selectLabel}
                    labelId={labelId}
                    id={id}
                    value={value}
                    name={name}
                    onChange={onChange}
                    fullWidth={true}
                    size={size}
                    required={required}
                    disabled={disabled}
                    renderValue={renderValue}
                    multiple={multiple}
                    sx={{
                        '.MuiSvgIcon-root': {
                            color: `${theme.palette.text.secondary} !important`,
                        },
                    }}
                >
                    {children}
                </MuiSelect>
                {helperText && <FormHelperText>{helperText ?? null}</FormHelperText>}
            </FormControl>
        </Box>
    );
};

export default Select;
