// DEPRECATED: Do not use
// REFACTOR: Find all uses and replace with AlertV2
import { FC } from 'react';
import './alert.scss';
import { useIsDesktop } from '../../CommonResources/hooks/useWindowSize';
import { Check, Warning, Error, Info } from '@mui/icons-material';

export interface IAlertProps {
    type: 'success' | 'warning' | 'error' | 'canceled' | 'informational';
    withIcon?: Boolean;
    title?: String;
    titlePosition?: 'center' | 'top';
    renderAction?: JSX.Element | String | null;
}

/**
 * @deprecated
 */
export const Alert: FC<IAlertProps> = ({ type, withIcon = true, title, titlePosition = 'center', renderAction }) => {
    const withoutIcon = !withIcon ? 'no-icon' : '';
    const noTitleNoIcon = title || withIcon ? '' : 'no-title-no-icon';
    const noTitle = title ? '' : 'no-title';
    const longTitle = title && title.length > 30 ? 'long-title' : '';
    const titleTop = titlePosition.match('top') ? 'title-top' : '';
    const isDesktop = useIsDesktop() && !(title && title.length > 30);

    const Icon = () => {
        if (type === 'success') {
            return <Check />;
        } else if (type === 'warning') {
            return <Warning />;
        } else if (type === 'error') {
            return <Error />;
        } else if (type === 'canceled') {
            return <Info />;
        } else if (type === 'informational') {
            return <Info />;
        }
        return <Info />;
    };

    return (
        <div className={`core-alert ${type} ${longTitle}`}>
            <div className={`wrapper ${noTitleNoIcon} ${withoutIcon} ${titleTop}`}>
                {withIcon && <Icon />}
                <span className={`title ${noTitle} ${withoutIcon}`}>{title}</span>
                {isDesktop && <div className={`text-block ${noTitleNoIcon} ${withoutIcon}`}>{renderAction}</div>}
            </div>
            {!isDesktop && <div className={`text-block-mobile`}>{renderAction}</div>}
        </div>
    );
};
