import { createEngine } from './createEngine';
import { transformToDPSValidationResultErrors } from './transformToDPSValidationResults';
import { ExecutionContext } from '../../../../services/backendServices/ViewModels';
import { DocumentApi } from '../../../../services/Document/Api';
import { RuntimeRule } from '../../CoreEngine/Utility/RuntimeRule';
import DropdownOptionsRepository from '../../../DocumentEditRules/PluginHost/services/dropdownOptionsRepository';
import { ContextInformationCollector } from './ContextInformationCollector';
import { getBuyerAndSupplierCompanyIdsByProfileType } from './getBuyerAndSupplierCompanyIdsByProfileType';

export async function executeValidationsForWorkflowEngine({ documentId }: { documentId: number }) {
    const documentApi = new DocumentApi();

    const res = await documentApi.getDocument(documentId);
    const document = res.data!;

    const vendorClass = document.BusinessDocFields.Invoice.VendorClass;

    const { buyerCompanyId, supplierCompanyId } = getBuyerAndSupplierCompanyIdsByProfileType({
        senderProfileType: document.CommonFields.SenderProfileType,
        receiverProfileType: document.CommonFields.ReceiverProfileType,
        receiverCompanyId: document.CommonFields.ReceiverCompanyId,
        senderCompanyId: document.CommonFields.SenderCompanyId,
    });

    const contextInformationCollector = new ContextInformationCollector({
        type: 'client',
        event: {
            type: 'WorkflowLoadEvent',
        },
        previousExecution: null,
    });

    const cjsEngine = await createEngine({
        context: ExecutionContext.UserWorkflowOrWorkflowActivity,
        buyerCompanyId,
        senderCompanyId: supplierCompanyId, // ? is this property poorly named?
        vendorClass,
        inWorkflowEngine: true,
    });

    const results = await cjsEngine.validateDocument({ document, contextInformationCollector });

    return transformToDPSValidationResultErrors(results);
}

export async function executeDPSValidations(
    buyerCompanyId: number,
    senderCompanyId: number,
    documentId: number,
    dpsRuleName: string,
    runAfterDPSRule: boolean,
    vendorClass?: string
) {
    const documentApi = new DocumentApi();
    const { data: document } = await documentApi.getDocument(documentId);

    const contextInformationCollector = new ContextInformationCollector({ type: 'server' });

    const cjsEngine = await createEngine({
        context: ExecutionContext.DPSRule,
        buyerCompanyId,
        runAfterDPSRule,
        dpsRuleName,
        senderCompanyId,
        vendorClass,
    });

    const results = document ? await cjsEngine.validateDocument({ document, contextInformationCollector }) : [];

    return transformToDPSValidationResultErrors(results);
}

interface ClientValidationData {
    context: ExecutionContext;
    buyerCompanyId: number;
    workflowId?: number;
    workflowActivityId?: number;
    senderCompanyId: number;
    vendorClass?: string;
    rulesFromClient: RuntimeRule[];
    dropdownRepository?: DropdownOptionsRepository;
}

export async function executeClientValidations({
    context,
    buyerCompanyId,
    workflowId,
    workflowActivityId,
    senderCompanyId,
    vendorClass,
    rulesFromClient,
    dropdownRepository,
}: ClientValidationData) {
    const cjsEngine = await createEngine({
        context,
        buyerCompanyId,
        workflowId,
        workflowActivityId,
        senderCompanyId,
        vendorClass,
        rulesFromClient,
        dropdownRepository,
    });

    return cjsEngine;
}
