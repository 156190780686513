import { AxiosResponse } from 'axios';
import { CompanyApi, ICompanyViewModel, IVendorClassViewModel } from '../Api';
import { ICompany, IVendorManagementFlags, IBuyerSearchGrid, GetTradingPartnersParams } from './types';
import {
    parseAttributes,
    serializeAttributes,
    validateAttributesDotNotation,
} from '../../../reusableFeatures/AttributeConfigurator/helpers';
import { IField } from '../../../reusableFeatures';
import { validateData } from '../../../ui';
import { ITechnicalDocument } from '../../../types';

export class CompanyService {
    api = new CompanyApi();

    public getCompany = async (companyId: number): Promise<ICompanyViewModel> => {
        const response = await this.api.getCompany(companyId);
        return response?.data[0];
    };

    public updateCompany = async (companyId: number, updatedCompanyData: Partial<ICompanyViewModel>): Promise<void> => {
        await this.api.updateCompany(companyId, updatedCompanyData);
    };

    public getAllBuyerCompanies = async (): Promise<ICompany[]> => {
        const response = await this.api.getAllBuyerCompanies();

        return (response.data ?? []).map((value) => ({ id: value.ID, name: value.Name }));
    };

    public getAllBuyerCompanyOptions = async (): Promise<
        {
            label: string;
            value: number;
        }[]
    > => {
        const allBuyerCompanies = await this.getAllBuyerCompanies();

        return allBuyerCompanies.map((company) => ({
            label: `${company.name} [${company.id}]`,
            value: company.id,
        }));
    };

    public getBuyerCompanyName = async () => {
        const allCompanies = await this.getAllBuyerCompanies();
        const lookup: { [companyId: number]: ICompany | undefined } = {};
        allCompanies.forEach((company) => {
            lookup[company.id] = company;
        });

        return (companyId: number | null) => {
            if (!companyId) {
                return '';
            }

            return lookup[companyId]?.name ?? '';
        };
    };

    public getBuyerCompaniesForDataGrid = async (name?: string, orderBy?: string): Promise<IBuyerSearchGrid> => {
        const response = await this.api.getBuyerCompaniesForDataGrid(name, orderBy);

        return {
            count: response.data.length,
            items: response.data.map((item: any) => ({
                id: item.ID,
                name: item.Name,
            })),
        };
    };

    public getVendorClasses = async (
        companyId: number
    ): Promise<AxiosResponse<IVendorClassViewModel[] | undefined>> => {
        const response = await this.api.getVendorClasses(companyId);

        return response;
    };

    public getVendorClassNames = async (companyId: number) => {
        const response = await this.api.getVendorClasses(companyId);

        if (!response.data) {
            return [];
        }

        return response.data.map((x) => x.VendorClassName);
    };

    public setVendorManagementVendorCustomAttributes = async (
        companyId: number,
        vendorAttributes: IField[]
    ): Promise<void> => {
        validateData((errors) => {
            validateAttributesDotNotation([], vendorAttributes, errors);
        });

        return this.api.updateCompany(companyId, {
            IsBuyer: true,
            VendorCustomAttributes: serializeAttributes(vendorAttributes),
        });
    };

    public setVendorManagementVendorSiteCustomAttributes = async (
        companyId: number,
        vendorSiteAttributes: IField[]
    ): Promise<void> => {
        validateData((errors) => {
            validateAttributesDotNotation([], vendorSiteAttributes, errors);
        });

        return this.api.updateCompany(companyId, {
            IsBuyer: true,
            VendorSiteCustomAttributes: serializeAttributes(vendorSiteAttributes),
        });
    };

    public async getTechnicalDocuments(
        companyId: number,
        $orderBy?: string
    ): Promise<AxiosResponse<ITechnicalDocument[] | undefined>> {
        return this.api.getTechnicalDocuments(companyId, $orderBy);
    }

    public async downloadTechnicalDocument(
        documentId: string
    ): Promise<AxiosResponse<ITechnicalDocument[] | undefined>> {
        return this.api.downloadTechnicalDoc({ documentId });
    }

    public getVendorAttributes = async (companyId: number): Promise<IField[]> => {
        const companyViewModel = await this.api.getCompany(companyId);
        return parseAttributes(companyViewModel.data[0]?.VendorCustomAttributes);
    };

    public getVendorSiteAttributes = async (companyId: number): Promise<IField[]> => {
        const companyViewModel = await this.api.getCompany(companyId);
        return parseAttributes(companyViewModel.data[0]?.VendorSiteCustomAttributes);
    };

    public getTradingPartnersByCompanyId = async (companyId: number, $filter: string) => {
        const res = await this.api.getTradingPartners({ companyId, $count: true, $filter });
        return res.data;
    };

    public getTradingPartners = async (params?: GetTradingPartnersParams) => {
        const res = await this.api.getTradingPartners({ ...params });
        return res.data;
    };

    public getVendorManagementFlags = async (companyId: number): Promise<IVendorManagementFlags> => {
        const response = await this.api.getBuyerPortalSettings(companyId);

        return {
            enableVendorEdits: response.data.EnableVendorEdits,
            supplierReferenceTable: response.data.SupplierReferenceTable === 1 ? 'vendor' : 'supplier',
        };
    };

    static defaultVendorAttributesCRUDFields: IField[] = [];

    static defaultVendorSiteAttributesCRUDFields: IField[] = [];
}
