import { IDocumentViewModel } from '../../../../services/Document/Api';
import { ExecutionContext } from '../../../../services/backendServices/ViewModels';
import { AxiosInstance } from 'axios';
import { RuntimeRule } from '../../CoreEngine/Utility/RuntimeRule';
import { UIDocumentController } from '../../ScriptingEngineEnvironment/Touchpoint';
import { ContextInformationCollector } from './ContextInformationCollector';

export class UIDocumentControllerTouchPoint implements UIDocumentController.IUIDocumentControllerTouchPoint {
    private registeredRules = new Map<
        string,
        {
            scriptName: string;
            configParams: RuntimeRule['configParams'];
            documentControllers: UIDocumentController.ControllerFn[];
        }
    >();

    private currentScript: string | null = null;

    private hasCompletedRegistration = false;

    constructor(
        private readonly context: ExecutionContext,
        private api: () => AxiosInstance,
        private buyerCompanyId?: number,
        private senderCompanyId?: number
    ) {}

    setCurrentScript(rule: RuntimeRule) {
        if (this.hasCompletedRegistration) {
            throw new Error('Registration has already been completed.');
        }

        this.currentScript = rule.name;

        this.registeredRules.set(rule.name, {
            scriptName: rule.name,
            configParams: rule.configParams,
            documentControllers: [],
        });
    }

    completeRegistration() {
        this.currentScript = null;
        this.hasCompletedRegistration = true;
    }

    register(options: UIDocumentController.RegisterControllerFunctionOptions): void {
        if (this.hasCompletedRegistration) {
            throw new Error('Tried to register a controller function after registration was completed.');
        }

        if (!this.currentScript) {
            throw new Error('Tried to register a controller function before setting a Script.');
        }

        const scriptConfig = this.registeredRules.get(this.currentScript)!;

        this.registeredRules.set(this.currentScript, {
            ...scriptConfig,
            documentControllers: [...scriptConfig.documentControllers, options.controllerFn],
        });
    }

    async executeControllerFunctions({
        document,
        documentStateUpdater,
        contextInformationCollector,
    }: {
        document: IDocumentViewModel;
        documentStateUpdater: (initialState: IDocumentViewModel, updatedState: IDocumentViewModel) => void;
        contextInformationCollector: ContextInformationCollector;
    }): Promise<void> {
        if (!this.hasCompletedRegistration) {
            throw new Error('Tried to execute controller functions before registration was completed.');
        }

        const controllerRegistrations = Array.from(this.registeredRules.values());

        // early return  if there are no controller functions
        if (controllerRegistrations.every(({ documentControllers }) => documentControllers.length === 0)) {
            return;
        }

        const initialDocumentState = JSON.parse(JSON.stringify(document)) as IDocumentViewModel;
        const mutableDocumentState = JSON.parse(JSON.stringify(document)) as IDocumentViewModel;

        const asyncControllers = new Array<Promise<void>>();

        controllerRegistrations.forEach(({ configParams, documentControllers }) => {
            documentControllers.forEach((controllerFn) => {
                const possiblePromise = controllerFn({
                    context: this.context,
                    api: this.api,
                    document: mutableDocumentState,
                    configParams,
                    buyerCompanyId: this.buyerCompanyId,
                    senderCompanyId: this.senderCompanyId,
                    contextInformation: contextInformationCollector.getContextInformation(),
                });

                if (typeof possiblePromise === 'object' && 'then' in possiblePromise) {
                    asyncControllers.push(possiblePromise);
                }
            });
        });

        await Promise.all(asyncControllers);

        documentStateUpdater(initialDocumentState, mutableDocumentState);
    }
}
