import { ReactNode, useEffect } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import { DataGrid, GridRowParams, GridSelectionModel, GridSortModel } from '@mui/x-data-grid';
import {
    Button,
    DataGridServer,
    FCNC,
    Form,
    GenericPageTemplate,
    LoadingDataGridServer,
    LoadingForm,
    Paper,
    TwoActionButtons,
} from '../../ui';
import { ISearchService, SearchCriteriaError } from './types';
import {
    FieldRendererOnChange,
    IField,
    IFieldRendererViewModel,
    renderFormColumns,
    SimpleFieldRendererViewModel,
} from '../FieldRenderer';
import { Box, Divider, Typography } from '@mui/material';
import { Add, Refresh } from '@mui/icons-material';
import { shouldPerformSearchForCriteria, useSearchFormState } from './internalState';
import { getGridColumns, getSearchFormFieldColumns, getTablePageSizeKey } from './configUtils';
import { getInstances, Permission } from '../../services/UserService/PortalUserService/indirection';
import { ExportData } from './ExportData';

export type SearchPageTemplateOnRowClick<T> = (params: GridRowParams<T>) => void;

export type SearchPageTemplateOnNewClick = () => void;

export interface ISearchPageTemplateProps<T = any> {
    /**
     * @deprecated
     */
    pageTitle?: ReactNode;
    entityType?: string | { singular: string; plural: string };
    testId: string;
    searchService: ISearchService<any>;
    onRowClick?: SearchPageTemplateOnRowClick<T>;
    onNewClick?: SearchPageTemplateOnNewClick;
    createPermission?: Permission;
    searchPermission?: Permission;
    /**
     * @deprecated
     */
    headerContent?: ReactNode;
    dontUpdateUrl?: boolean;
    checkbox?: boolean;
    isRowSelectable?: (params: GridRowParams<T>) => boolean;
    gridControls?: ReactNode | ((clearCachedDataset: () => void) => ReactNode);
    onSelectionModelChange?: (ids: GridSelectionModel, rows: T[]) => void;
    selectionModel?: GridSelectionModel;
    instructions?: ReactNode;
    useQueryOptions?: UseQueryOptions<any, any, any, any>;
    onSearchClick?: () => void;
    CustomRightFooter?: React.FC<{
        onChange: FieldRendererOnChange<IFieldRendererViewModel>;
        viewModel: SimpleFieldRendererViewModel<any>;
    }>;
    gridTitle?: string | ((info: { totalCount: number; pluralEntityType: string }) => string);
    gridText?: string;
    enableCsvExport?: boolean;
    onFormFieldChange?: (
        fieldRendererViewModel: SimpleFieldRendererViewModel<any>,
        fieldRendererOnChange: FieldRendererOnChange<any>,
        fieldKey: IField['dataBindingKey']
    ) => void;
    defaultSort?: GridSortModel;
    validateFields?: (fieldRendererViewModel: SimpleFieldRendererViewModel<any>) => SearchCriteriaError | true;
    exportInfoMessage?: string;
    invalidateCacheOnSearchClick?: boolean;
    paginationMode?: 'client' | 'server';
}

export const SearchPageTemplate: FCNC<ISearchPageTemplateProps> = ({
    searchService,
    onRowClick,
    pageTitle,
    entityType,
    testId,
    headerContent,
    dontUpdateUrl = false,
    checkbox,
    isRowSelectable,
    gridControls,
    onSelectionModelChange,
    selectionModel,
    instructions,
    useQueryOptions,
    createPermission,
    onNewClick,
    searchPermission,
    onSearchClick,
    CustomRightFooter,
    gridTitle,
    gridText,
    enableCsvExport,
    onFormFieldChange,
    defaultSort,
    validateFields,
    exportInfoMessage,
    invalidateCacheOnSearchClick = false,
    paginationMode = 'server',
}) => {
    const searchFormTestId = `${testId}-search-form`;
    const currentUser = getInstances().useCurrentUser('mustBeLoggedIn');

    const singularEntityType = entityType && (typeof entityType === 'string' ? entityType : entityType.singular);
    const pluralEntityType = entityType && (typeof entityType === 'string' ? `${entityType}s` : entityType.plural);

    if (pluralEntityType) {
        pageTitle = `Search ${pluralEntityType}`;
    }

    const canCreate = !createPermission || currentUser.permissions.has(createPermission);
    if (singularEntityType && onNewClick && canCreate) {
        headerContent = (
            <Button testid="Add-New-Entity" onClick={onNewClick}>
                <Add />
                New {singularEntityType}
            </Button>
        );
    }

    const canSearch = !searchPermission || currentUser.permissions.has(searchPermission);

    // fetch search configuration from the search service
    const { data: searchConfiguration, isLoading: isLoadingSearchConfiguration } = useQuery(
        [searchService.key, 'Configuration'],
        () => searchService.fetchSearchConfiguration()
    );

    // manage the search form state
    const {
        state: { appliedCriteria, enteredCriteria, page, pageSize, sortModel },
        dispatch,
        searchRequest,
        initialValuesSearchFormState,
    } = useSearchFormState(searchConfiguration, searchService, dontUpdateUrl);

    useEffect(() => {
        if (pageSize) {
            localStorage.setItem(getTablePageSizeKey(searchService.key), JSON.stringify(pageSize));
        }
    }, [pageSize, searchService.key]);

    const shouldPerformSearch = shouldPerformSearchForCriteria(searchConfiguration, searchRequest.searchQuery);
    const canPerformSearch = shouldPerformSearchForCriteria(searchConfiguration, enteredCriteria);

    // fetch the search results whenever the search request changes
    const {
        isLoading: isLoadingResults,
        isPreviousData,
        isFetching: isFetchingResults,
        error: resultsError,
        data: results,
    } = useQuery(
        [searchService.key, 'Results', searchRequest],
        async () => {
            return searchService.fetchResults(searchConfiguration!, searchRequest);
        },
        {
            enabled: !!searchConfiguration && shouldPerformSearch,
            keepPreviousData: true,
            retry: false,
            ...(useQueryOptions as any),
        }
    );

    // this indicates whether the search service has cached a dataset. if so, the user should be able to purge
    const hasCachedDataset = !!searchService.getDataSetKey;
    const queryClient = useQueryClient();
    const clearCachedDataset = () => {
        if (!!searchService.getDataSetKey) {
            queryClient.invalidateQueries(searchService.getDataSetKey(searchConfiguration!, searchRequest));
        }

        queryClient.invalidateQueries([searchService.key, 'Results']);
    };

    // this indicates we are fetching updated search results, while displaying cached results to the user
    const isFetchingLatestResults = !isPreviousData && !isLoadingResults && isFetchingResults;

    // this indicates we are fetching search page results for a page that isn't cached yet
    const isFetchingNextPageResults =
        (isPreviousData && isFetchingResults) || isLoadingResults || isLoadingSearchConfiguration;

    const setCriteria = (payload: SimpleFieldRendererViewModel<any>) =>
        dispatch({ type: 'SET_ENTERED_CRITERIA', payload });
    const fieldRendererOnChange: FieldRendererOnChange<any> = (updater) => setCriteria(updater(enteredCriteria));
    const onChange: FieldRendererOnChange<any> = (updater, fieldDataBindingKey) => {
        const newCriteria = updater(enteredCriteria);
        setCriteria(newCriteria);
        onFormFieldChange?.(newCriteria, fieldRendererOnChange, fieldDataBindingKey);
    };

    const searchFormButtons = (
        <TwoActionButtons
            testId={`${testId}-search-form-buttons`}
            submitButton={{
                text: 'Search',
                isDisabled: !canPerformSearch,
            }}
            cancelButton={{
                text: 'Clear',
                onClick: () => {
                    dispatch({ type: 'OVERWRITE_STATE_FROM_SAVED_CRITERIA', payload: initialValuesSearchFormState });
                },
            }}
            customRightFooter={
                CustomRightFooter && <CustomRightFooter viewModel={enteredCriteria} onChange={onChange} />
            }
            isLoading={isFetchingResults || isLoadingSearchConfiguration}
        />
    );

    if (!canSearch) {
        return (
            <GenericPageTemplate
                title="Access Denied"
                body={<>You do not have sufficient permissions to search {pluralEntityType}</>}
            />
        );
    }

    // Render loading page while waiting for search configuration to load
    if (!searchConfiguration) {
        return (
            <GenericPageTemplate
                title={pageTitle}
                body={
                    <>
                        <LoadingForm
                            testId={searchFormTestId}
                            loadingMessage="Loading Search Form Configuration"
                            formButtons={searchFormButtons}
                            fakeFieldSpec={[3, 3]}
                        />
                        <LoadingDataGridServer />
                    </>
                }
            />
        );
    }

    const searchFormFieldColumns = getSearchFormFieldColumns(searchConfiguration, enteredCriteria);

    // Rendering search page with current state and configuration

    const isLoading = isFetchingLatestResults || isFetchingNextPageResults;

    return (
        <GenericPageTemplate
            title={pageTitle}
            body={
                <>
                    {instructions}
                    {searchFormFieldColumns && (
                        <Form
                            testId={`${testId}-search-form`}
                            columns={renderFormColumns({
                                viewModel: enteredCriteria,
                                onChange,
                                columns: searchFormFieldColumns,
                            })}
                            formButtons={searchFormButtons}
                            onSubmit={() => {
                                if (
                                    invalidateCacheOnSearchClick &&
                                    JSON.stringify(appliedCriteria.state) === JSON.stringify(enteredCriteria.state)
                                ) {
                                    clearCachedDataset();
                                } else {
                                    dispatch({ type: 'SUBMIT', payload: { sortModel: defaultSort || [] } });
                                }
                                if (onSearchClick) {
                                    onSearchClick();
                                }
                            }}
                            infoMessage={
                                canPerformSearch
                                    ? null
                                    : 'Enter search criteria, then click "Search" to perform search.'
                            }
                            errorMessage={(() => {
                                if (resultsError instanceof SearchCriteriaError) {
                                    return resultsError.message;
                                }

                                const fieldError = validateFields?.(enteredCriteria);

                                if (fieldError instanceof SearchCriteriaError) {
                                    return fieldError.message;
                                }

                                const userClickedSearchAndDidntChangeCriteria = appliedCriteria === enteredCriteria;
                                const shouldShowCriteriaError =
                                    !canPerformSearch && userClickedSearchAndDidntChangeCriteria;
                                if (shouldShowCriteriaError) {
                                    return 'Enter search criteria, then click "Search" to perform search.';
                                }
                                return null;
                            })()}
                            requiredMessage={false}
                        />
                    )}
                    {shouldPerformSearch && hasCachedDataset && !isFetchingLatestResults && (
                        <Paper
                            sx={{
                                mb: 2,
                                p: 1,
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <div>
                                <Typography>
                                    The latest search results might not be displayed in the grid below to enhance
                                    performance.
                                </Typography>
                            </div>
                            <Button onClick={clearCachedDataset}>
                                <Refresh />
                                Refresh Results
                            </Button>
                        </Paper>
                    )}
                    <Divider sx={{ mb: 3 }} />
                    {shouldPerformSearch && !(resultsError instanceof SearchCriteriaError) && (
                        <>
                            {(!!gridTitle || !!gridText || !!enableCsvExport) && (
                                <Box
                                    sx={{
                                        mb: 2,
                                        py: 1,
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        gap: 2,
                                    }}
                                >
                                    <Box>
                                        {!isLoading && (
                                            <Typography component="h2" variant="h3">
                                                {typeof gridTitle === 'string'
                                                    ? gridTitle
                                                    : gridTitle?.({
                                                          totalCount: results?.totalResultCount || 0,
                                                          pluralEntityType: pluralEntityType ?? '',
                                                      }) ?? ''}
                                            </Typography>
                                        )}
                                        <Typography>{gridText}</Typography>
                                    </Box>
                                    {enableCsvExport && (
                                        <ExportData
                                            searchService={searchService}
                                            searchConfiguration={searchConfiguration}
                                            searchRequest={searchRequest}
                                            singularEntityType={singularEntityType || ''}
                                            exportInfoMessage={exportInfoMessage}
                                        />
                                    )}
                                </Box>
                            )}
                            {paginationMode == 'client' ? (
                                <div
                                    data-testid={testId}
                                    style={{ height: 36 * (pageSize ?? searchConfiguration.defaultPageSize) + 122 }}
                                >
                                    <DataGrid
                                        rows={results?.pageResults ?? []}
                                        columns={getGridColumns(searchConfiguration, searchRequest.searchQuery)}
                                        pageSize={pageSize ?? searchConfiguration.defaultPageSize}
                                        rowsPerPageOptions={[10]}
                                        rowHeight={34}
                                        onRowClick={onRowClick}
                                        loading={isLoading}
                                        error={resultsError}
                                    />
                                </div>
                            ) : (
                                <DataGridServer
                                    testId={`${testId}-results-grid`}
                                    checkbox={checkbox}
                                    isRowSelectable={isRowSelectable}
                                    rowCount={results?.totalResultCount ?? 0}
                                    columns={getGridColumns(searchConfiguration, searchRequest.searchQuery)}
                                    onRowClick={onRowClick}
                                    isLoading={isLoading}
                                    error={resultsError}
                                    data={isLoading ? [] : results?.pageResults}
                                    pageSize={pageSize ?? searchConfiguration.defaultPageSize}
                                    page={page}
                                    sortModel={sortModel}
                                    onPageChange={(payload) => dispatch({ type: 'SET_PAGE', payload })}
                                    onPageSizeChange={(payload) => dispatch({ type: 'SET_PAGE_SIZE', payload })}
                                    onSortModelChange={(payload) => dispatch({ type: 'SET_SORT', payload })}
                                    rowsPerPageOptions={searchConfiguration.pageSizeOptions}
                                    onSelectionModelChange={(ids: GridSelectionModel) => {
                                        const rows = results?.pageResults ?? [];
                                        const selectedIDs = new Set(ids);
                                        const selectedRowData = rows.filter((row) => selectedIDs.has(row.id));
                                        onSelectionModelChange?.(ids, selectedRowData);
                                    }}
                                    selectionModel={selectionModel}
                                />
                            )}
                            {selectionModel && selectionModel.length > 0 && gridControls && (
                                <Box
                                    sx={{
                                        my: 2,
                                        py: 1,
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    {typeof gridControls === 'function'
                                        ? gridControls(clearCachedDataset)
                                        : gridControls}
                                </Box>
                            )}
                        </>
                    )}
                </>
            }
            headerContent={headerContent}
        />
    );
};
