import { DashboardApi, StraightThruBreakdownReport } from '../../../Api';
import { SeriesLineOptions } from 'highcharts';
import { DashboardReportService } from '../../DashboardReportService';
import { createDashboardReportService } from '../../createDashboardReportService';
import { DashboardDataPayload } from '../../../Api/DashboardDataPayload';
import { dashboardFormatDate } from '../../../Charts/Chart/Chart.utils';

export class StraightThroughBreakdownService
    implements
        DashboardReportService<
            StraightThruBreakdownReport,
            {
                dates: string[];
                multlineChartData: Pick<SeriesLineOptions, 'data' | 'name' | 'marker'>[];
            }
        >
{
    private api = new DashboardApi();

    get storedProcedure() {
        return 'StraightThruBreakdownReport' as const;
    }

    async fetchReport(settings: DashboardDataPayload) {
        const res = await this.api.getDashboardReportData<StraightThruBreakdownReport>(settings, this.storedProcedure);
        return res.data;
    }

    get nullifyDataIfEmptyObj() {
        return {
            optIn: true,
            xAxisPropertyKey: 'Date' as const,
        };
    }

    transformVMToChartData(vm: StraightThruBreakdownReport['viewModel']) {
        const dates = new Array<string>();

        const autoGLCoding: Pick<SeriesLineOptions, 'data' | 'name' | 'marker'> = {
            name: 'Auto-GL Coding',
            data: [],
            marker: {
                enabled: true,
            },
        };
        const poMatch: Pick<SeriesLineOptions, 'data' | 'name' | 'marker'> = {
            name: 'PO Match',
            data: [],
            marker: {
                enabled: true,
            },
        };
        const approvalRouting: Pick<SeriesLineOptions, 'data' | 'name' | 'marker'> = {
            name: 'Approval Routing',
            data: [],
            marker: {
                enabled: true,
            },
        };

        vm.forEach((p) => {
            dates.push(dashboardFormatDate(p.Date));
            autoGLCoding.data?.push(p.AutoGLCodingPercent);
            poMatch.data?.push(p.POCMPercent);
            approvalRouting.data?.push(p.AutoRoutedPercent);
        });

        return {
            multlineChartData: [autoGLCoding, poMatch, approvalRouting],
            dates,
        };
    }

    async exportCSV(settings: DashboardDataPayload) {
        return this.api.exportDashboardReport(settings, this.storedProcedure);
    }
}

export const useStraightThroughBreakdownService = createDashboardReportService(StraightThroughBreakdownService);
