import { ProfileApi } from '../Api';
import {
    IProfile,
    IProfileDeliveryFileType,
    IProfileDeliveryMethod,
    IProfileExtendedData,
    IUpdateInvoiceProfile,
    ProfileType,
} from './types';

import { backendServices } from '../..';

export class ProfileService {
    api = new ProfileApi();

    public updateProfile = async (
        id: number,
        { updatedData, selectedProfileLogo }: { updatedData: IUpdateInvoiceProfile; selectedProfileLogo?: File }
    ): Promise<void> => {
        await this.api.updateProfile(id, updatedData, selectedProfileLogo);
    };

    public getProfile = async (id: number, profileType?: ProfileType): Promise<IProfile | undefined> => {
        const response = await this.api.getProfile(id, profileType);
        return this.mapToProfile(response.data[0]);
    };

    public searchProfiles = async (profileType: ProfileType, companyId?: number): Promise<IProfile[]> => {
        const response = await this.api.searchProfiles(profileType, companyId);
        const profiles = response.data.map((item) => this.mapToProfile(item));
        return profiles.filter((profile) => profile !== undefined) as IProfile[];
    };

    public getAllCompanyProfileOptions = async (profileType: ProfileType, companyId?: number) => {
        const allCompanyProfiles = await this.searchProfiles(profileType, companyId);

        return allCompanyProfiles?.map((profile) => ({
            label: `${profile.name} [${profile.id}]`,
            value: profile.id,
        }));
    };

    public searchProfilesPaged = async (params: {
        $count?: boolean;
        $top: number;
        $skip: number | undefined;
        profileType: ProfileType;
        profileId?: string;
        profileName?: string;
        companyId?: string;
        companyName?: string;
    }): Promise<{ items: IProfile[]; count?: number }> => {
        const response = await this.api.searchProfilesPaged(params);
        const profiles = response.data?.Items.map((item) => this.mapToProfile(item));
        return {
            items: profiles.filter((profile) => profile !== undefined) as IProfile[],
            count: response.data.Count ?? undefined,
        };
    };

    private mapToProfile = (profile?: backendServices.ViewModels.ProfileViewModel): IProfile | undefined => {
        if (!profile) {
            return undefined;
        }

        return {
            id: profile.Id,
            apeMail: profile.APEMail,
            address1: profile.Address1,
            address2: profile.Address2,
            address3: profile.Address3,
            city: profile.City,
            companyId: profile.CompanyID,
            companyVatRegistrationNumber: profile.CompanyVatRegistrationNumber,
            country: profile.Country,
            currency: profile.Currency,
            currency_us: profile.Currency_US,
            countryOfEstablishment: profile.CountryOfEstablishment,
            email: profile.Email,
            fax: profile.Fax,
            invoiceConfirmationEmail: profile.InvoiceConfirmationEmail,
            invoiceProfileType: profile.InvoiceProfileType,
            invoiceProfileType_us: profile.InvoiceProfileType_US,
            logoPath: profile.LogoPath,
            name: profile.Name,
            phone: profile.Phone,
            postalCode: profile.PostalCode,
            profileDeliveryMethods: profile.ProfileDeliveryMethods.map((item) => this.mapToProfileDeliveryMethod(item)),
            profileExtendedDatas: profile.ProfileExtendedDatas.map((item) => this.mapToProfileExtendedData(item)),
            profileType: profile.ProfileType,
            remitToAddressBlock: profile.RemitToAddressBlock,
            remittanceAdviceEmail: profile.RemittanceAdviceEmail,
            ruleStatus: profile.RuleStatus,
            ruleStatus_us: profile.RuleStatus_US,
            signVATInvoices: profile.SignVATInvoices,
            signature: profile.Signature,
            state: profile.State,
            vatComplianceRequired: profile.VATComplianceRequired,
            vatNumber: profile.VATNumber,
            vatRegistrationNumber: profile.VatRegistrationNumber,
            scanKeyProfileId: profile.ScanKeyProfileId,
            ruleConfigurationName: profile.RuleConfigurationName,
            PODeliveryMethodStatus: profile.PODeliveryMethodStatus,
            deliveryMethodIsEditable: profile.DeliveryMethodIsEditable,
        };
    };

    private mapToProfileDeliveryMethod = (
        method: backendServices.ViewModels.ProfileDeliveryMethodViewModel
    ): IProfileDeliveryMethod => {
        return {
            addressLine1: method.AddressLine1,
            addressLine2: method.AddressLine2,
            addressLine3: method.AddressLine3,
            businessDocType: method.BusinessDocType,
            city: method.City,
            combinedCustomZipFileNamePattern: method.CombinedCustomZipFileNamePattern,
            country: method.Country,
            deliveryMethod: method.DeliveryMethod,
            emailAddress: method.EmailAddress,
            faxNumber: method.FaxNumber,
            groupBy: method.GroupBy,
            groupReleaseSchedule: method.GroupReleaseSchedule,
            id: method.ID,
            isEnabled: method.IsEnabled,
            lastUpdateDate: method.LastUpdateDate,
            name: method.Name,
            noVATDelivery: method.NoVATDelivery,
            postRecipient: method.PostRecipient,
            postalCode: method.PostalCode,
            processingMode: method.ProcessingMode,
            profileDeliveryFileTypes: method.ProfileDeliveryFileTypes.map((item) =>
                this.mapToProfileDeliveryFileType(item)
            ),
            profileId: method.ProfileId,
            separateCustomZipFileNamePattern: method.SeparateCustomZipFileNamePattern,
            settings: method.Settings,
            state: method.State,
            testDelivery: method.TestDelivery,
            uri: method.Uri,
            whenUsed: method.WhenUsed,
            zipFileCounter: method.ZipFileCounter,
            zipFileMaxDigits: method.ZipFileMaxDigits,
        };
    };

    private mapToProfileDeliveryFileType = (
        fileType: backendServices.ViewModels.ProfileDeliveryFileTypeViewModel
    ): IProfileDeliveryFileType => {
        return {
            combinedCustomFileNamePattern: fileType.CombinedCustomFileNamePattern,
            counter: fileType.Counter,
            deliveryMethodId: fileType.DeliveryMethodId,
            fileSubType: fileType.FileSubType,
            fileType: fileType.FileType,
            groupingMethod: fileType.GroupingMethod,
            id: fileType.ID,
            isEnabled: fileType.IsEnabled,
            lastUpdateDate: fileType.LastUpdateDate,
            maxDigits: fileType.MaxDigits,
            separateCustomFileNamePattern: fileType.SeparateCustomFileNamePattern,
            settings: fileType.Settings,
            xslt: fileType.Xslt,
        };
    };

    private mapToProfileExtendedData = (
        extendedData: backendServices.ViewModels.ProfileExtendedDataViewModel
    ): IProfileExtendedData => {
        return {
            description: extendedData.Description,
            fieldType: extendedData.FieldType,
            id: extendedData.ID,
            label: extendedData.Label,
            lastUpdateDate: extendedData.LastUpdateDate,
            name: extendedData.Name,
            profileId: extendedData.ProfileId,
            value: extendedData.Value,
            visibleToSupplier: extendedData.VisibleToSupplier,
        };
    };
}
