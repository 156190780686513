import { useQuery } from '@tanstack/react-query';
import { backendServices } from '../../services';
import { RuntimeRuleFetcher } from '../CustomJSRuleEngineV2/CoreEngine/Utility/RuntimeRuleFetcher';
import {
    DocumentValidationConfiguration,
    extractValidationConfiguration,
} from './ClientValidationController/DocumentValidationConfiguration';

export function useRuntimeRules({
    context,
    validationsEnabled,
    buyerCompanyId,
    workflowId,
    workflowActivityId,
    vendorClass,
}: {
    context: backendServices.ViewModels.ExecutionContext;
    validationsEnabled: boolean;
    buyerCompanyId?: number | null;
    workflowId?: number;
    workflowActivityId?: number;
    vendorClass?: string;
}) {
    const shouldFetch = buyerCompanyId != null && validationsEnabled;

    const { data, isLoading } = useQuery(
        ['ValidationRuntimeRules', context, buyerCompanyId, workflowId, workflowActivityId, vendorClass],
        async () => {
            let validationConfiguration: DocumentValidationConfiguration | null = null;

            const ruleFetcher = new RuntimeRuleFetcher();
            const runtimeRules = await ruleFetcher.fetchRules(
                {
                    engineVersion: 2,
                    buyerCompanyId: buyerCompanyId!,
                    executionContext: context,
                    workflowId,
                    workflowActivityId,
                    vendorClass,
                },
                (mapping) => {
                    const configuration = extractValidationConfiguration(mapping, context, vendorClass);

                    validationConfiguration = configuration;
                }
            );

            return {
                runtimeRules,
                validationConfiguration: validationConfiguration as DocumentValidationConfiguration | null,
            };
        },
        {
            enabled: shouldFetch,
            staleTime: Infinity,
        }
    );

    return {
        runtimeRules: data?.runtimeRules,
        isLoading,
        validationConfiguration: data?.validationConfiguration ?? null,
    };
}
