import { useQuery } from '@tanstack/react-query';
import { CompanyService, backendServices, portalUserService } from '../../services';

const determineSenderCompanyId = (context: backendServices.ViewModels.ExecutionContext, senderCompanyId: number) => {
    if (context === backendServices.ViewModels.ExecutionContext.UserWorkflowOrWorkflowActivity) {
        return senderCompanyId;
    }

    if (context === backendServices.ViewModels.ExecutionContext.WebEntry) {
        return portalUserService.getCurrentCompanyId();
    }

    throw new Error('Unexpected execution context.');
};

export function useVendorForCustomJSRuleEngineV2({
    buyerCompanyId,
    context,
    senderCompanyId: senderCompanyIdFromDoc,
}: {
    buyerCompanyId?: number | null;
    context: backendServices.ViewModels.ExecutionContext;
    senderCompanyId?: number;
}) {
    const senderCompanyId = senderCompanyIdFromDoc
        ? determineSenderCompanyId(context, senderCompanyIdFromDoc)
        : undefined;

    const { data: vendorClass, isLoading: vendorIsLoading } = useQuery(
        ['VendorForCustomJSRuleEngineV2', buyerCompanyId, senderCompanyId],
        async () => {
            const companyService = new CompanyService();

            const res = await companyService.getTradingPartnersByCompanyId(
                senderCompanyId!,
                `BuyerCompanyId eq ${buyerCompanyId}`
            );

            return res.Items?.[0]?.VendorClass;
        },
        {
            enabled: buyerCompanyId != null && senderCompanyId != null,
        }
    );

    return {
        vendorClass: vendorClass ?? undefined,
        vendorIsLoading,
    };
}
