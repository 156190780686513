import { createContext, useContext, useCallback } from 'react';
import { createPluginServices } from '../PluginHost';
import { useDocumentEditValidator } from '../DocumentEditValidator';
import { MessageClasses } from '../types/private';
import DropdownOptionsRepository from '../PluginHost/services/dropdownOptionsRepository';
import { useInternalRulesValidatorContext } from '../internalRules';
import { useMessageClassesContext } from '../MessageClassesProvider';

const DocumentSectionContext = createContext<'header' | 'body' | 'footer' | null>(null);

const FieldNameContext = createContext<string>('');

const BodyLineNumberContext = createContext<number | null>(null);

const GLSplitNumberContext = createContext<number | null>(null);

const CollapsedSectionNameContext = createContext<string | null>(null);

export const DocumentSectionProvider = DocumentSectionContext.Provider;

export const BodyLineNumberProvider = BodyLineNumberContext.Provider;

export const GLSplitNumberProvider = GLSplitNumberContext.Provider;

export const CollapsedSectionNameProvider = CollapsedSectionNameContext.Provider;

export const FieldNameProvider = FieldNameContext.Provider;

const pluginServices = createPluginServices('', new DropdownOptionsRepository());

export function useCreateFieldKey() {
    const documentSection = useContext(DocumentSectionContext);
    const bodyLineNumber = useContext(BodyLineNumberContext);
    const glSplitNumber = useContext(GLSplitNumberContext);
    const collapsedSectionName = useContext(CollapsedSectionNameContext);

    const createFieldKey = useCallback(
        (fieldName: string) => {
            if (documentSection === 'body') {
                if (bodyLineNumber !== null && glSplitNumber !== null) {
                    return pluginServices.associatedField.glField({
                        lineNumber: bodyLineNumber,
                        splitNumber: glSplitNumber,
                        fieldName,
                    });
                }

                if (bodyLineNumber !== null && glSplitNumber === null) {
                    return pluginServices.associatedField.bodyField({
                        lineNumber: bodyLineNumber,
                        fieldName,
                    });
                }

                console.error(
                    'Encountered field rendered in body section with no line number. This was not expected to ever occur.'
                );
            }

            if (documentSection === 'header') {
                if (collapsedSectionName) {
                    return pluginServices.associatedField.collapsedHeaderField({
                        sectionName: collapsedSectionName,
                        fieldName,
                    });
                }

                if (bodyLineNumber === null && glSplitNumber !== null) {
                    return pluginServices.associatedField.glHeaderField({
                        splitNumber: glSplitNumber,
                        fieldName,
                    });
                }

                return pluginServices.associatedField.headerField(fieldName);
            }

            if (documentSection === 'footer') {
                return pluginServices.associatedField.footerField(fieldName);
            }

            console.error(
                'Encountered unknown field location trying to determine field key. Returning empty field key.'
            );
            return '';
        },
        [documentSection, bodyLineNumber, glSplitNumber, collapsedSectionName]
    );

    return createFieldKey;
}

export function useFieldKey(fieldName: string) {
    const createFieldKey = useCreateFieldKey();
    return createFieldKey(fieldName);
}

export function useBodyAllLinesFieldKey(fieldName: string) {
    return pluginServices.associatedField.bodyFieldAllLines(fieldName);
}

export function useCreateGetGlCodingAllSplitsFieldKey() {
    return (fieldName: string) => pluginServices.associatedField.glFieldAllSplits(fieldName);
}

export function useGLCodingAllSplitsFieldKey(fieldName: string) {
    const createFieldKey = useCreateGetGlCodingAllSplitsFieldKey();

    return createFieldKey(fieldName);
}

export function useCreateGetGlCodingAllSplitsOnLineFieldKey() {
    const currentLine = useContext(BodyLineNumberContext);

    return (fieldName: string) => {
        if (!currentLine) {
            return '';
        }

        return pluginServices.associatedField.glFieldAllSplitsOnLine({
            fieldName,
            lineNumber: currentLine,
        });
    };
}

export function useGLCodingSectionKey() {
    return pluginServices.associatedField.glCodingSection();
}

/**
 * Creates a function that gets the field control state for a field.
 * @returns a function that takes a list of keys identifying the field hierarchy, ordered by least specific to most specific (allows more specific keys to override less specific keys), and that returns field control state for the field.
 */
export function useCreateGetFieldControlState() {
    const { getFieldControlState } = useDocumentEditValidator();

    const { getFieldControlState: getFieldControlStateIR } = useInternalRulesValidatorContext();

    return (fieldKeys: string[]) =>
        fieldKeys.reduce(
            (acc, x) => {
                const stateFromV1 = getFieldControlState(x);
                const stateFromIR = getFieldControlStateIR(x);

                return {
                    ...acc,
                    ...stateFromIR,
                    ...stateFromV1,
                };
            },
            { visible: true, editable: true }
        );
}

/**
 * Get the field control state for a field.
 * @param fieldKeys keys for the field hierarchy ordered by least specific to most specific (allows more specific keys to override less specific keys)
 * @returns the field control state for the field.
 */
export function useFieldControlState(fieldKeys: string[]) {
    const getFieldControlState = useCreateGetFieldControlState();
    return getFieldControlState(fieldKeys);
}

export type SpecificFieldMessages = { [key in keyof MessageClasses]: string[] | undefined };

export function useGetFieldErrors() {
    const createFieldKey = useCreateFieldKey();

    const messageClasses = useMessageClassesContext();

    return (fieldName: string) => {
        const result: any = {};

        Object.keys(messageClasses).forEach((key) => {
            const fieldResults = messageClasses[key as keyof MessageClasses].fieldMessages[createFieldKey(fieldName)];

            if (fieldResults == null) {
                return;
            }

            result[key] ??= [];
            result[key].push(...fieldResults);
        });

        if (Object.keys(result).length) {
            return result as SpecificFieldMessages;
        }

        return undefined;
    };
}

export function useHasAnyGLErrorsOnLine() {
    const documentSection = useContext(DocumentSectionContext);
    const bodyLineNumber = useContext(BodyLineNumberContext);
    const { validatorResultState } = useDocumentEditValidator();
    const messageClasses = useMessageClassesContext();

    const prefix = `glField-${bodyLineNumber}`;

    if (documentSection === 'body' && bodyLineNumber !== null && 'messageClasses' in validatorResultState) {
        if (
            Object.keys(validatorResultState.messageClasses.errorMessages.fieldMessages).some((x) =>
                x.startsWith(prefix)
            )
        ) {
            return true;
        }

        if (Object.keys(messageClasses.errorMessages.fieldMessages).some((x) => x.startsWith(prefix))) {
            return true;
        }
    }

    return false;
}

export function useGetFieldHasErrors() {
    const getFieldErrors = useGetFieldErrors();

    return (fieldName: string) => {
        const errors = getFieldErrors(fieldName);

        return !!errors?.errorMessages;
    };
}

export function useFieldErrors(fieldName: string) {
    const getFieldErrors = useGetFieldErrors();
    return getFieldErrors(fieldName);
}

export function useContextualFieldName() {
    return useContext(FieldNameContext);
}

export function useContextualFieldErrors() {
    const fieldName = useContext(FieldNameContext);
    const errors = useFieldErrors(fieldName);
    return errors;
}

export function useContextualFieldHasErrors() {
    const errors = useContextualFieldErrors();

    return !!errors?.errorMessages;
}

export function useBodyLineNumberState() {
    return useContext(BodyLineNumberContext);
}

export interface IFieldErrorsInjectedProps {
    documentEditValidationFieldErrors: SpecificFieldMessages | undefined;

    documentEditValidationFieldHasErrors: boolean;

    documentEditValidationFieldName: string;
}

export function withContextualFieldErrors<P>(
    Component: React.ComponentType<P & IFieldErrorsInjectedProps>
): React.ComponentType<P> {
    return (props: P) => {
        const name = useContextualFieldName();
        const errors = useContextualFieldErrors();
        const fieldKey = useFieldKey(name);
        const hasErrors = useContextualFieldHasErrors();

        return (
            <Component
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                documentEditValidationFieldErrors={errors}
                documentEditValidationFieldHasErrors={hasErrors}
                documentEditValidationFieldName={name}
                documentEditValidationFieldKey={fieldKey}
            />
        );
    };
}
