import { SeriesPieOptions } from 'highcharts';
import { DashboardApi, TopSuppliersWithoutStraightThroughProcessing } from '../../../Api';
import { DashboardDataPayload } from '../../../Api/DashboardDataPayload';
import { createDashboardReportService } from '../../createDashboardReportService';
import { DashboardReportService } from '../../DashboardReportService';

class TopSuppliersWithoutStraightThroughProcessingReportService
    implements DashboardReportService<TopSuppliersWithoutStraightThroughProcessing, SeriesPieOptions['data']>
{
    private api = new DashboardApi();

    get storedProcedure() {
        return 'TopSuppliersWithoutStraightThroughProcessingDashboardReport' as const;
    }

    async fetchReport(settings: DashboardDataPayload) {
        const res = await this.api.getDashboardReportData<TopSuppliersWithoutStraightThroughProcessing>(
            settings,
            this.storedProcedure
        );
        return res.data;
    }

    get nullifyDataIfEmptyObj() {
        return {
            optIn: true,
            xAxisPropertyKey: undefined,
        };
    }

    transformVMToChartData(vm: TopSuppliersWithoutStraightThroughProcessing['viewModel']) {
        return vm.map((p) => ({
            y: p.NumDocsWithUserTasks,
            name: p.Name,
            label: p.Name,
        }));
    }

    async exportCSV(settings: DashboardDataPayload) {
        return this.api.exportDashboardReport(settings, this.storedProcedure);
    }
}

export const useTopSuppliersWithoutStraightThroughProcessingReportService = createDashboardReportService(
    TopSuppliersWithoutStraightThroughProcessingReportService
);
