import { IPortalUser, ExtraFeature } from './types';
import {
    UserApi,
    ILoginResponseViewModel,
    IUserViewModel,
    APIPermissions,
    APIPermission,
    APIPermissionViewModel,
    RoleType,
} from '../Api';
import { getPermissions } from './rolePermissionMapping';
import { EventEmitter, isInternalLogin } from '../../../utils';
import { CompanyService } from '../../Company';
import { getAllUserRoles } from './getAllUserRoles';

export class PortalUserService {
    private api = new UserApi();

    private emitter = new EventEmitter<IPortalUser | null>();

    private currentUser: IPortalUser | null = null;

    public subscribe = this.emitter.subscribe.bind(this.emitter);

    /**
     * Returns the current PortalUser or null if no user is logged in.
     */
    public getCurrentUser<T extends 'mustBeLoggedIn' | undefined>(
        mode?: T
    ): T extends 'mustBeLoggedIn' ? IPortalUser : IPortalUser | null {
        const user = this.currentUser;

        if (mode === 'mustBeLoggedIn' && !user) {
            throw new Error('User must be logged in');
        }

        return user as any;
    }

    /**
     * Returns the company id the current user is currently logged in under.
     */
    public getCurrentCompanyId() {
        const user = this.getCurrentUser('mustBeLoggedIn');

        if (!user.currentCompanyId) {
            throw new Error('User must be logged in under a company id');
        }

        return user.currentCompanyId;
    }

    /**
     * Clears the current user. Call during log out.
     */
    public clearCurrentUser() {
        this.setCurrentUser(null);
    }

    /**
     * Load a Buyer Portal PortalUser as the current user. Call during log in.
     */
    public async loadBuyerPortalUser(response: ILoginResponseViewModel): Promise<void> {
        const user = await this.getPortalUser(response);
        await this.determineBuyerPortalUserFeatures(user);
        this.setCurrentUser(user);
    }

    /**
     * Load a Supplier Portal PortalUser as the current user. Call during log in.
     */
    public async loadSupplierPortalUser(response: ILoginResponseViewModel): Promise<void> {
        const user = await this.getPortalUser(response);
        await this.determineSupplierPortalUserFeatures(user);
        this.setCurrentUser(user);
    }

    /**
     * Load a Customer Service Portal PortalUser as the current user. Call during log in.
     */
    public async loadCustomerServicePortalUser(response: ILoginResponseViewModel): Promise<void> {
        const user = await this.getPortalUser(response);
        await this.determineCustomerServicePortalUserFeatures(user);
        this.setCurrentUser(user);
    }

    private getUserPermissionKeys(response: APIPermissionViewModel[]): readonly APIPermission[] {
        return response
            .map((permission) => permission.Name)
            .filter((permissionKey): permissionKey is APIPermission =>
                APIPermissions.includes(permissionKey as APIPermission)
            );
    }

    private async getPortalUser(loginResponse: ILoginResponseViewModel) {
        const userId = Number(loginResponse.userId);
        const response = await this.api.getUser(userId);
        const userViewModel = this.assignAllRolesToInternalUser(response.data[0]);

        const { data: permissionData } = await this.api.getPermissionsForUser(userId);

        if (!userViewModel) {
            throw new Error(`Expected user to be returned for ${userId}`);
        }

        const userPermissions = this.getUserPermissionKeys(permissionData);

        const user: IPortalUser = {
            id: userViewModel.ID,
            companyId: userViewModel.CompanyID,
            currentCompanyId: Number(loginResponse.companyId),
            name: userViewModel.Name,
            userName: userViewModel.UserName,
            email: userViewModel.Email,
            phone: userViewModel.Phone,
            permissions: getPermissions(userViewModel.UserRoles, userPermissions),
            extraFeatures: new Set<ExtraFeature>(),
        };

        return user;
    }

    private async determineBuyerPortalUserFeatures(user: IPortalUser) {
        const companyService = new CompanyService();

        // determine if VendorManagement feature is enabled
        const vendorManagementFlags = await companyService.getVendorManagementFlags(user.currentCompanyId!);
        if (vendorManagementFlags.supplierReferenceTable === 'vendor') {
            user.extraFeatures.add('VendorManagement.Enabled');
        }
        if (vendorManagementFlags.supplierReferenceTable === 'vendor' && vendorManagementFlags.enableVendorEdits) {
            user.extraFeatures.add('VendorManagement.VendorEditEnabled');
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
    private async determineSupplierPortalUserFeatures(user: IPortalUser) {
        if (user.permissions.has('AccountProfile.EditProfile')) {
            user.extraFeatures.add('AccountProfile.EditProfileEnabled');
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
    private async determineCustomerServicePortalUserFeatures(user: IPortalUser) {}

    private setCurrentUser(user: IPortalUser | null) {
        this.currentUser = user;
        this.emitter.notify(this.currentUser);
    }

    /** Methods to aid in automated testing */

    /**
     * Calls a modifier function with the current user instance to allow changing user
     * properties for tests / debugging purposes.
     *
     * This can be used to quickly assign granular permissions to a user or extra features to
     * a user to see how the application behaves in different cases.
     *
     * @param modifier function to modify the current user instance
     */
    public modifyUserForTest(modifier: (user: IPortalUser) => void): void {
        const user = this.currentUser;

        if (!user) {
            return;
        }

        const newUser = {
            ...user,
            permissions: new Set(user.permissions),
            extraFeatures: new Set(user.extraFeatures),
        };

        modifier(newUser);

        this.setCurrentUser(newUser);
    }

    /**
     * Temporary method to load fake Customer Service Portal PortalUser until authentication
     * for the portal is implemented.
     */
    public loadFakeCustomerServicePortalUser() {
        const setWithEverything = new Set<any>();
        setWithEverything.has = () => true;

        this.setCurrentUser({
            id: 1,
            companyId: null,
            currentCompanyId: null,
            name: 'admin',
            userName: 'admin',
            email: 'admin@transcepta.com',
            phone: '555-555-5555',
            permissions: setWithEverything,
            extraFeatures: setWithEverything,
        });
    }

    /**
     * Creates a fake user for a test and runs the modifier to modify its attributes.
     * @param modifier function to modify the fake user
     */
    public loadUserForTest(modifier: (user: IPortalUser) => void) {
        this.loadFakeCustomerServicePortalUser();
        this.modifyUserForTest(modifier);
    }

    private readonly rolesNotMeantForInternalUsers = [
        // Internal users shouldn't have the ability to edit and/or process documents
        RoleType.SuperUserApprover,
    ];

    /**
     * Assigns all roles to an internal user object
     * @param user the user object
     * @returns the user object with all roles assigned if they logged in with internal login
     */
    public assignAllRolesToInternalUser(user: IUserViewModel) {
        if (isInternalLogin()) {
            return {
                ...user,
                UserRoles: getAllUserRoles(user.ID).filter(
                    (role) => !this.rolesNotMeantForInternalUsers.includes(role.RoleID)
                ),
            };
        }

        return user;
    }
}

/**
 * The API for managing current logged in user state on our portals.
 */
export const portalUserService = new PortalUserService();

window.transceptaPortalUserService = portalUserService;

declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface Window {
        transceptaPortalUserService: PortalUserService;
    }
}
