import { DashboardApi, StraightThroughPOMatchDashboardReport } from '../../../Api';
import { SeriesColumnOptions } from 'highcharts';
import { DashboardReportService } from '../../DashboardReportService';
import { createDashboardReportService } from '../../createDashboardReportService';
import { DashboardDataPayload } from '../../../Api/DashboardDataPayload';
import { dashboardFormatDate } from '../../../Charts/Chart/Chart.utils';

export class StraightThruPoMatchReportService
    implements DashboardReportService<StraightThroughPOMatchDashboardReport, SeriesColumnOptions['data']>
{
    private api = new DashboardApi();

    get storedProcedure() {
        return 'StraightThroughPOMatchDashboardReport' as const;
    }

    async fetchReport(settings: DashboardDataPayload) {
        const res = await this.api.getDashboardReportData<StraightThroughPOMatchDashboardReport>(
            settings,
            this.storedProcedure
        );
        return res.data;
    }

    get nullifyDataIfEmptyObj() {
        return {
            optIn: true,
            xAxisPropertyKey: 'Date' as const,
        };
    }

    transformVMToChartData(vm: StraightThroughPOMatchDashboardReport['viewModel']) {
        return vm.map((p) => ({
            y: p.Count,
            name: dashboardFormatDate(p.Date),
            label: dashboardFormatDate(p.Date),
        }));
    }

    async exportCSV(settings: DashboardDataPayload) {
        return this.api.exportDashboardReport(settings, this.storedProcedure);
    }
}

export const useStraightThruPoMatchReportService = createDashboardReportService(StraightThruPoMatchReportService);
