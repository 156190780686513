import { SeriesPieOptions } from 'highcharts';
import { DashboardApi, InvoicesRightFirstTimeDashboardsReportDonut } from '../../../Api';
import { DashboardDataPayload } from '../../../Api/DashboardDataPayload';
import { createDashboardReportService } from '../../createDashboardReportService';
import { DashboardReportService } from '../../DashboardReportService';

class InvoicesRightFirstTimeDonutReportService
    implements DashboardReportService<InvoicesRightFirstTimeDashboardsReportDonut, SeriesPieOptions['data']>
{
    private api = new DashboardApi();

    get storedProcedure() {
        return 'InvoicesRightFirstTimeDashboardsReportDonut' as const;
    }

    async fetchReport(settings: DashboardDataPayload) {
        const res = await this.api.getDashboardReportData<InvoicesRightFirstTimeDashboardsReportDonut>(
            settings,
            this.storedProcedure
        );

        return res.data;
    }

    get nullifyDataIfEmptyObj() {
        return {
            optIn: true,
            xAxisPropertyKey: undefined,
        };
    }

    transformVMToChartData(vm: InvoicesRightFirstTimeDashboardsReportDonut['viewModel']) {
        const results = vm[0];

        return [
            {
                name: 'Compliant Invoices',
                label: 'Compliant Invoices',
                y: results.PercentRightFirstTime,
            },
            {
                name: 'Non-compliant (Rejected)',
                label: 'Non-compliant (Rejected)',
                y: results.PercentRejected,
            },
        ];
    }
}

export const useInvoicesRightFirstTimeDonutReportService = createDashboardReportService(
    InvoicesRightFirstTimeDonutReportService
);
