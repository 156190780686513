import { AxiosResponse } from 'axios';
import { IValidationFailureTypeViewModel } from './ViewModels';
import { api } from '../../../services/api';
import ICreatedUpdateDeleteViewModel from '../../Common/Api/ViewModels/CreatedUpdateDeleteViewModel';

class ValidationFailureTypesApi {
    route = '/ValidationFailureType';

    public getAllValidationFailureTypes = async (
        filter?: string
    ): Promise<AxiosResponse<IValidationFailureTypeViewModel[] | undefined>> => {
        const params = {
            $filter: filter,
        };

        return api().get(`${this.route}`, { params });
    };

    public getValidationFailureTypesByCompanyId = async (
        buyerCompanyId: number,
        filter?: string
    ): Promise<AxiosResponse<IValidationFailureTypeViewModel[]>> => {
        const params = {
            buyerCompanyId,
            $filter: filter,
        };

        return api().get(`${this.route}`, { params });
    };

    public getValidationFailureTypes = async (
        id: number,
        filter?: string
    ): Promise<AxiosResponse<IValidationFailureTypeViewModel[] | undefined>> => {
        const params = {
            $filter: filter,
        };

        return api().get(`${this.route}/${id}`, { params });
    };

    public updateValidationFailureTypes = async (
        id: number,
        validationFailureType: IValidationFailureTypeViewModel
    ): Promise<AxiosResponse<ICreatedUpdateDeleteViewModel>> => {
        return api().put(`${this.route}/${id}`, validationFailureType);
    };

    public createValidationFailureTypes = async (
        validationFailureType: IValidationFailureTypeViewModel
    ): Promise<AxiosResponse<ICreatedUpdateDeleteViewModel>> => {
        return api().post(`${this.route}`, validationFailureType);
    };

    public deleteValidationFailureTypes = async (
        id: number
    ): Promise<AxiosResponse<ICreatedUpdateDeleteViewModel | undefined>> => {
        return api().delete(`${this.route}/${id}`);
    };
}

export default ValidationFailureTypesApi;
