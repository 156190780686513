import { ISelectProps, Select, SelectItem } from '../Select';
import { IBaseSelectProps, OptionType, useMultiSelectOptionHelpers } from './selectHelpers';
import { ReadOnly } from '../ReadOnly';
import { useFieldsReadonly } from '../Form';
import { Box, BoxProps, SxProps, Theme } from '@mui/material';
import { ReactNode } from 'react';
import { renderTooltipLabel } from '../Tooltip';

export interface IMultiSortedSelectProps<T, V>
    extends IBaseSelectProps<T, V>,
        Omit<ISelectProps, 'onChange' | 'value'> {
    nullOptionLabel?: string;
    prompt?: string;
    renderLabel?: (label: string) => ReactNode;
    inputLabelSx?: SxProps<Theme>;
    stacked?: boolean;
    labelBoxSx?: BoxProps['sx'];
}

export function MultiSortedSelect<T>({
    nullOptionLabel,
    options,
    prompt,
    label,
    labelId,
    id,
    value,
    name,
    onChange,
    disableSorting,
    sortComparisonFn,
    readonly,
    required,
    testId,
    tooltip,
    disabled,
    renderValue,
    renderLabel,
    inputLabelSx,
    stacked,
    labelBoxSx,
    helperText,
    error,
}: IMultiSortedSelectProps<T, OptionType<T>[]>) {
    const { normalizedOptions, normalizedValues, handleChange } = useMultiSelectOptionHelpers(
        value,
        options,
        onChange,
        disableSorting,
        sortComparisonFn
    );
    const isReadonly = useFieldsReadonly(readonly);

    const nullOption =
        typeof nullOptionLabel === 'string' ? (
            <SelectItem
                value={nullOptionLabel}
                sx={{
                    '& > .blank-label': {
                        height: '1em',
                    },
                }}
            >
                {nullOptionLabel.length ? nullOptionLabel : <div className="blank-label" />}
            </SelectItem>
        ) : null;
    const promptItem = prompt ? (
        <SelectItem value={prompt} disabled>
            {prompt}
        </SelectItem>
    ) : null;

    function handleValueChange(newValue: string[]) {
        handleChange(newValue);
    }

    if (isReadonly) {
        return <ReadOnly testId={testId} label={label} value={normalizedValues?.map((v) => v.label).join(',')} />;
    }

    if (required) {
        label = `${label} *`;
    }

    return (
        <Box
            sx={
                stacked
                    ? { display: 'flex', flexDirection: 'column', width: '100%' }
                    : { display: 'flex', alignItems: 'center', width: '100%' }
            }
        >
            {label ? (
                <Box component="label" htmlFor={id}>
                    <Box sx={{ width: 160, ...labelBoxSx }}>{renderTooltipLabel({ tooltip, label })}</Box>
                </Box>
            ) : null}
            <Select
                testId={testId}
                disabled={disabled}
                id={id}
                labelId={labelId}
                value={normalizedValues.map((v) => v.label) ?? [nullOptionLabel] ?? [prompt] ?? []}
                name={name}
                onChange={(ev) => handleValueChange(ev.target.value as string[])}
                required={required}
                renderValue={renderValue}
                inputLabelSx={inputLabelSx}
                multiple={true}
                helperText={helperText}
                error={error}
            >
                {promptItem}
                {nullOption}
                {normalizedOptions.map((option) => {
                    const isSelected = normalizedValues.findIndex((val) => val.label === option.label) !== -1;
                    return (
                        <SelectItem
                            data-testid={`${testId}-${option.label}`}
                            value={option.label}
                            key={option.label}
                            sx={{
                                fontWeight: (theme) =>
                                    isSelected ? theme.typography.fontWeightBold : theme.typography.fontWeightRegular,
                            }}
                        >
                            {renderLabel ? renderLabel(option.label) : option.label}
                        </SelectItem>
                    );
                })}
            </Select>
        </Box>
    );
}
