import {
    IDocumentValidationTouchpoint,
    IValidationResultsCollector,
    RegisterValidationFunctionOptions,
    ValidationFn,
} from '../../ScriptingEngineEnvironment/Touchpoint/DocumentValidation';
import { IDocumentViewModel } from '../../../../services/Document/Api';
import { ExecutionContext } from '../../../../services/backendServices/ViewModels';
import { AxiosInstance } from 'axios';
import { ContextInformationCollector } from './ContextInformationCollector';
import { RuntimeRule } from '../../CoreEngine/Utility/RuntimeRule';
import { ValidationResultsCollector } from './ValidationResultsCollector';

export class DocumentValidationTouchpoint implements IDocumentValidationTouchpoint {
    private registeredRules = new Map<
        string,
        {
            scriptName: string;
            configParams: RuntimeRule['configParams'];
            validations: ValidationFn[];
        }
    >();

    private currentScript: string | null = null;

    private hasCompletedRegistration = false;

    private results = new ValidationResultsCollector();

    constructor(
        private readonly context: ExecutionContext,
        private api: () => AxiosInstance,
        private buyerCompanyId?: number,
        private senderCompanyId?: number
    ) {}

    setCurrentScript(rule: RuntimeRule) {
        if (this.hasCompletedRegistration) {
            throw new Error('Registration has already been completed.');
        }

        this.currentScript = rule.name;

        this.registeredRules.set(rule.name, {
            scriptName: rule.name,
            configParams: rule.configParams,
            validations: [],
        });
    }

    completeRegistration() {
        this.currentScript = null;
        this.hasCompletedRegistration = true;
    }

    register(options: RegisterValidationFunctionOptions): void {
        if (this.hasCompletedRegistration) {
            throw new Error('Tried to register a validation function after registration was completed.');
        }

        if (!this.currentScript) {
            throw new Error('Tried to register a validation function before setting a Script.');
        }

        const scriptConfig = this.registeredRules.get(this.currentScript)!;

        this.registeredRules.set(this.currentScript, {
            ...scriptConfig,
            validations: [...scriptConfig.validations, options.validate],
        });
    }

    async executeValidationFunctions({
        document,
        contextInformationCollector,
    }: {
        document: IDocumentViewModel;
        contextInformationCollector: ContextInformationCollector;
    }) {
        if (!this.hasCompletedRegistration) {
            throw new Error('Tried to execute validation functions before registration was completed.');
        }

        this.results = new ValidationResultsCollector();

        const asyncValidations = new Array<Promise<void>>();

        Array.from(this.registeredRules.values()).forEach(({ configParams, validations }) => {
            validations.forEach((validationFn) => {
                const possiblePromise = validationFn(
                    this.context,
                    this.api,
                    this.results,
                    document,
                    configParams,
                    this.buyerCompanyId,
                    this.senderCompanyId,
                    contextInformationCollector.getContextInformation()
                );

                if (typeof possiblePromise === 'object' && 'then' in possiblePromise) {
                    asyncValidations.push(possiblePromise);
                }
            });
        });

        await Promise.all(asyncValidations);

        return this.results.getResults();
    }

    public getResults(): IValidationResultsCollector {
        return this.results;
    }
}
