import { FC } from 'react';
import { Grid } from '../Grid';
import { Typography, GridProps } from '@mui/material';

export interface IReadOnlyProps {
    label?: string;
    value?: string | number;
    sx?: GridProps['sx'];
    testId?: string;
}

export const ReadOnly: FC<IReadOnlyProps> = ({ label, value, sx, testId }) => {
    if (!label) {
        return (
            <Grid container spacing={1} columns={8} sx={sx} data-testid={testId}>
                <Grid item xs={8} md={8}>
                    <Typography variant="body1" data-testid={`${testId}-value`} className="ReadOnly-value">
                        {value}
                    </Typography>
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid container spacing={1} columns={8} sx={sx} data-testid={testId}>
            <Grid item xs={4} md={4} sx={{ margin: 'auto' }}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }} data-testid={`${testId}-label`}>
                    {label}
                </Typography>
            </Grid>
            <Grid item xs={4} md={4} sx={{ margin: 'auto' }}>
                <Typography variant="body1" data-testid={`${testId}-value`} className="ReadOnly-value">
                    {value}
                </Typography>
            </Grid>
        </Grid>
    );
};
