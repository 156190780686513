import { AxiosResponse } from 'axios';
import { api } from '../../api';
import { IVendorSiteViewModel, IVendorSites } from './types';

export class VendorSiteApi {
    route = 'Company/VendorSite';

    public getVendorSites = (
        params: Record<string, string | number | boolean | null | undefined>
    ): Promise<AxiosResponse<IVendorSites>> => {
        return api().get(`${this.route}`, { params });
    };

    public getVendorSite = (id: number): Promise<AxiosResponse<IVendorSiteViewModel[]>> => {
        return api().get(`${this.route}/${id}`);
    };

    public updateVendorSite = (
        id: number,
        data: Partial<IVendorSiteViewModel>
    ): Promise<AxiosResponse<IVendorSiteViewModel | null>> => {
        return api().put(`${this.route}/${id}`, data);
    };

    public createVendor = (data: Partial<IVendorSiteViewModel>): Promise<AxiosResponse<{ ID: number }>> => {
        return api().post(this.route, data);
    };

    public deleteVendorSite = (id: number): Promise<AxiosResponse<IVendorSiteViewModel | null>> => {
        return api().delete(`${this.route}/${id}`);
    };
}
