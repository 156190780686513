import { DashboardApi, InvoicesDelivered } from '../../../Api';
import { dashboardFormatDate } from '../../../Charts/Chart/Chart.utils';
import { SeriesColumnOptions } from 'highcharts';
import { DashboardReportService } from '../../DashboardReportService';
import { createDashboardReportService } from '../../createDashboardReportService';
import { DashboardDataPayload } from '../../../Api/DashboardDataPayload';

export class InvoicesDeliveredReportService
    implements DashboardReportService<InvoicesDelivered, SeriesColumnOptions['data']>
{
    private api = new DashboardApi();

    get storedProcedure() {
        return 'InvoicesDeliveredDashboardReport' as const;
    }

    async fetchReport(settings: DashboardDataPayload) {
        const res = await this.api.getDashboardReportData<InvoicesDelivered>(settings, this.storedProcedure);
        return res.data;
    }

    get nullifyDataIfEmptyObj() {
        return {
            optIn: true,
            xAxisPropertyKey: 'Date' as const,
        };
    }

    transformVMToChartData(vm: InvoicesDelivered['viewModel']) {
        return vm.map((p) => ({
            y: p.InvoicesDeliveredCount,
            name: dashboardFormatDate(p.Date),
            label: dashboardFormatDate(p.Date),
            originalDate: p.Date,
        }));
    }

    async exportCSV(settings: DashboardDataPayload) {
        return this.api.exportDashboardReport(settings, this.storedProcedure);
    }
}

export const useInvoicesDeliveredReportService = createDashboardReportService(InvoicesDeliveredReportService);
