import { api } from './api';
import apiPaths from './paths';
import { createDocumentBuyerMessageFilter } from './DocumentThunk.filter';

class DocumentThunk {
    fetchDocumentStatus = (params) => {
        return api()
            .get(apiPaths.validateDocument, { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    fetchDraftDocumentStatus = (params) => {
        return api()
            .get(apiPaths.validateDraftDocument, { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    fetchDocumentList = (params, headers = {}) => {
        return api()
            .get(`${apiPaths.document}`, { headers, params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    fetchUserGroups = (params, headers = {}) => {
        return api()
            .get(`${apiPaths.userGroup}`, { headers, params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    fetchVendor = (params) => {
        return api()
            .get(`${apiPaths.readCompany}Vendor/`, { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    fetchVendorList = ({ companyId, ...params }) =>
        api()
            .get(`${apiPaths.listVendors.replace(':id', companyId)}`, { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );

    fetchDocumentLayout = (params) => {
        return api()
            .get(apiPaths.documentLayout, { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    saveLayoutOverrides = (params) => {
        return api()
            .post(apiPaths.documentLayout, params.layoutViewModel)
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    fetchDocumentHistory = (params) => {
        return api()
            .get(apiPaths.documentHistory, { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    fetchDocument = (params) => {
        return api()
            .get(apiPaths.readDocument.replace(':id', params.documentId))
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    fetchDocumentDraft = (params) => {
        return api()
            .get(apiPaths.documentDraft, { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    deleteDocumentDraft = (params) => {
        return api()
            .delete(apiPaths.documentDraft, { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    fetchDocumentAttachments = (params) => {
        return api()
            .get(apiPaths.documentAttachments, { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    saveDocumentAttachments = (params) => {
        return api()
            .post(apiPaths.saveDocumentAttachments.replace(':documentId', params.documentId), params.fileFormData)
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    fetchDocumentDraftAttachments = (params) => {
        return api()
            .get(apiPaths.documentDraftAttachments, { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    // Document with actual Dynamic Discounting: 24345
    fetchDynamicDiscounting = (params) => {
        return api()
            .get(apiPaths.dynamicDiscounting, { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    // Document Delivery Details
    fetchDeliveryDetails = (params) => {
        return api()
            .get(apiPaths.documentDelivery, { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    fetchPDF = (params) => {
        return api()
            .get(apiPaths.fetchPDF, { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    generatePDF = (params) => {
        return api()
            .get(apiPaths.generatePDF, { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    fetchAttachment = (params) => {
        return api()
            .get(apiPaths.downloadDocumentAttachment, {
                params,
            })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    fetchDeliveredDocument = ({ deliveryGroupId, fileName, ...params }) => {
        return api()
            .get(
                apiPaths.downloadDeliveredDocument
                    .replace(':deliveryGroupId', deliveryGroupId)
                    .replace(':fileName', fileName),
                { params }
            )
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    flipDocument = (params) => {
        return api()
            .get(apiPaths.flipDocument, { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    saveInvoiceDraft = (params) => {
        return api()
            .post(apiPaths.documentDraft, params.document)
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    saveDocumentDraft = (params) => {
        return api()
            .put(apiPaths.documentDraftSave.replace(':documentId', params.documentId), params.document)
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    createDocumentDraft = (params) => {
        return api()
            .post(apiPaths.documentDraft, params.document)
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    submitInvoice = (params) => {
        return api()
            .post(apiPaths.document, params.document)
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    submitDocument = (params) => {
        return api()
            .put(apiPaths.documentDraftSave.replace(':documentId', params.documentId), params.document)
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    // for buyer invoice creation
    createNewDocument = ({ workflowId, document }) => {
        const path = apiPaths.document;

        return api()
            .post(path, document, { params: { workflowId } })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    // for buyer invoice creation
    createNewDocumentWithFile = ({ workflowId, document, guidFileName }) => {
        const path = apiPaths.document;

        return api()
            .post(path, document, { params: { workflowId, guidFileName } })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    updateDocument = ({ documentId, document, deleteLine, saveValidationFailureDetails } = {}) => {
        const path = apiPaths.readDocument.replace(':id', documentId);

        return api()
            .put(path, document, { params: { deleteLine, saveValidationFailureDetails } })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    saveDocumentDraftAttachments = (params) => {
        return api()
            .post(
                apiPaths.saveDocumentDraftAttachments
                    .replace(':draftId', params.draftId)
                    .replace(':businessDocType', params.businessDocType)
                    .replace(':documentId', params.documentId),
                params.fileFormData
            )
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    fetchDocumentSkeleton = (params) => {
        return api()
            .get(apiPaths.documentSkeleton, { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    resendDocument = (params) => {
        return api()
            .post(apiPaths.resendDocument.replace(':documentId', params.documentId))
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    fetchSearchConfig = (params) => {
        return api()
            .get(apiPaths.documentSearchConfiguration, { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    deleteAttachment = (params) => {
        return api()
            .delete(apiPaths.deleteDocumentAttachment.replace(':id', params.id))
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    fetchDocumentBuyerMessage = (params) => {
        const {
            companyId,
            docState,
            vendorClass, // number | null
            businessDocType,
        } = params;

        const filters = createDocumentBuyerMessageFilter(docState, vendorClass, businessDocType);

        const query = [`companyId=${companyId}`, `$filter=${filters}`, `$orderBy desc`].join('&');

        return api()
            .get(`${apiPaths.documentBuyerMessage}?${query}`)
            .then((messages) => messages.data)
            .catch((error) => {
                console.warn('Error while retrieving document messages', error);
            });
    };

    fetchDocumentActiveWorkflowDocumentCount = (params) => {
        return api()
            .get(apiPaths.documentActiveWorkflowDocumentCount, { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };
}

export default DocumentThunk;
