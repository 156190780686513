import { conditionalOptions, setRelativeComplement } from '../../../utils';
import { CompanyService } from '../../Company';
import { VendorService } from './VendorService';
import { IPortalUser, portalUserService } from '../../UserService';
import {
    ISearchConfiguration,
    ISearchRequest,
    ISearchResponse,
    ISearchService,
    createTextField,
    createCheckboxField,
    createAutocompleteField,
    createSimpleDropdownOptions,
    createUseSearchService,
    SimpleFieldRendererViewModel,
    createRadioButtonGroup,
} from '../../../reusableFeatures';
import { Link } from '@mui/icons-material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { TOOLTIP_CELL_MODE } from '../../../ui';
import { getVendorSearchModeText } from '../../../utils/configurableMessages/getVendorSearchModeText';
import { InvoiceProfileType } from '../../Company/Api';
import { Typography } from '@mui/material';
import { ProfileService, ProfileType } from '../../Profile';

interface IVendorSearchData {
    name: string;

    vendorNumber: string;

    vendorClass: string | null;

    contactName: string;

    contactEmail: string;

    includeDisabledSuppliers: boolean;

    includeConnectedVendorsOnly: boolean;

    siteNumber: string;
}

export class VendorSearchService implements ISearchService<IVendorSearchData> {
    private companyService = new CompanyService();

    private vendorService = new VendorService();

    private profileService = new ProfileService();

    async fetchSearchConfiguration(): Promise<ISearchConfiguration<IVendorSearchData>> {
        const currentUser = portalUserService.getCurrentUser('mustBeLoggedIn');
        const companyId = portalUserService.getCurrentCompanyId();

        const vendorClasses = await this.companyService.getVendorClassNames(companyId);

        const profiles = await this.profileService.searchProfiles(ProfileType.BuyerDestination, companyId);

        const shouldDisplayScanKeyOrNormalColumn = profiles.some((profile) => !!profile.scanKeyProfileId);

        function handleRenderLinkColumn(params: GridRenderCellParams) {
            // params.id will have the following format:
            // CompanyID-VendorID-VendorNumber
            // If any of those 3 IDs are null (missing), then vendor and supplier are not connected
            // Also if search radio button 'Search Connected Suppliers' is ON, id returned includes nulls so we won't display this link icon
            if (!params.id.toString().includes('null')) {
                if (params.cellMode === TOOLTIP_CELL_MODE) {
                    return 'Vendor and Supplier Connected';
                }

                return <Link />;
            }
            return null;
        }

        return {
            gridColumns: (criteria) => [
                {
                    field: 'name',
                    headerName: 'Name',
                    flex: 1,
                },
                {
                    field: 'link',
                    headerName: '',
                    width: 40,
                    renderCell: handleRenderLinkColumn,
                    sortable: false,
                },
                { field: 'vendorNumber', headerName: 'Vendor Number', flex: 0.5 },
                { field: 'vendorClass', headerName: 'Vendor Class', flex: 0.5 },
                ...conditionalOptions(shouldDisplayScanKeyOrNormalColumn, [
                    {
                        field: 'invoiceProfileType',
                        headerName: 'Scan/Key or Normal',
                        flex: 0.5,
                        renderCell: (params: GridRenderCellParams<InvoiceProfileType>) => (
                            <Typography>
                                {params.value === InvoiceProfileType.ScanKey ? 'Scan/Key' : 'Normal'}
                            </Typography>
                        ),
                    },
                ]),
                ...conditionalOptions(this.shouldUseVendorSearchWithCriteria(currentUser, criteria), [
                    { field: 'siteNumber', headerName: 'Site Number', flex: 0.5 },
                ]),
                { field: 'contactName', headerName: 'Contact Name', flex: 1, sortable: false },
                { field: 'contactEmail', headerName: 'Contact Email', flex: 1.5, sortable: false },
            ],
            enabledExportingMethods: [],
            searchFormFieldColumns: (criteria) => {
                const leftColumn = [
                    createTextField({
                        id: 'name',
                        label: 'Name',
                        defaultValue: '',
                        dataBindingKey: 'name',
                    }),
                    createTextField({
                        id: 'vendorNumber',
                        label: 'Vendor Number',
                        defaultValue: '',
                        dataBindingKey: 'vendorNumber',
                    }),
                    ...conditionalOptions(vendorClasses.length > 0, [
                        createAutocompleteField({
                            id: 'vendorClass',
                            label: 'Vendor Class',
                            defaultValue: null,
                            dataBindingKey: 'vendorClass',
                            options: createSimpleDropdownOptions(vendorClasses),
                        }),
                    ]),
                    ...conditionalOptions(this.shouldUseVendorSearchWithCriteria(currentUser, criteria), [
                        createTextField({
                            id: 'siteNumber',
                            label: 'Site Number',
                            defaultValue: '',
                            dataBindingKey: 'siteNumber',
                        }),
                    ]),
                ];

                const vendorSearchModeText = getVendorSearchModeText();

                const rightColumn = [
                    createTextField({
                        id: 'contactName',
                        label: 'Contact Name',
                        defaultValue: '',
                        dataBindingKey: 'contactName',
                    }),
                    createTextField({
                        id: 'contaactEmail',
                        label: 'Contact Email',
                        defaultValue: '',
                        dataBindingKey: 'contactEmail',
                    }),
                    createCheckboxField({
                        id: 'includeDisabledSuppliers',
                        label: this.shouldUseVendorSearchWithCriteria(currentUser, criteria)
                            ? 'Include Disabled Vendors'
                            : 'Include Disabled Suppliers',
                        defaultValue: false,
                        dataBindingKey: 'includeDisabledSuppliers',
                    }),
                    ...conditionalOptions(currentUser.extraFeatures.has('VendorManagement.Enabled'), [
                        createRadioButtonGroup({
                            id: 'includeConnectedVendorsOnly',
                            dataBindingKey: 'includeConnectedVendorsOnly',
                            label: vendorSearchModeText.label,
                            defaultValue: false,
                            options: [
                                { label: vendorSearchModeText.searchVendorsText, value: false },
                                { label: vendorSearchModeText.searchSuppliersText, value: true },
                            ],
                            tooltip: vendorSearchModeText.tooltip && {
                                message: vendorSearchModeText.tooltip,
                                icon: 'Help',
                            },
                        }),
                    ]),
                ];

                return [leftColumn, rightColumn];
            },
            pageSizeOptions: [10, 25, 50],
            defaultPageSize: 10,
            searchWithNoCriteria: false,
            isCriteriaEmpty: (criteria, filledFields) => {
                // the API won't allow searching with only the checkbox fields selected. currently we wish
                // to force the user to "narrow" the search criteria to reduce DB load. in the future when
                // we have server side caching applied to the trading partner search endpoint, we will allow
                // searching without any criteria and remove this check.
                return (
                    setRelativeComplement(filledFields, ['includeConnectedVendorsOnly', 'includeDisabledSuppliers'])
                        .length === 0
                );
            },
        };
    }

    async fetchResults(
        _searchConfiguration: ISearchConfiguration<IVendorSearchData>,
        searchRequest: ISearchRequest<IVendorSearchData>
    ): Promise<ISearchResponse> {
        const currentUser = portalUserService.getCurrentUser('mustBeLoggedIn');
        const companyId = portalUserService.getCurrentCompanyId();

        const response = await this.vendorService.getVendorSearchGrid(
            companyId,
            true,
            searchRequest.pageSize,
            searchRequest.pageNumber,
            searchRequest.sort,
            searchRequest.searchQuery.state.name,
            searchRequest.searchQuery.state.contactName,
            searchRequest.searchQuery.state.contactEmail,
            searchRequest.searchQuery.state.vendorNumber,
            searchRequest.searchQuery.state.vendorClass,
            searchRequest.searchQuery.state.includeDisabledSuppliers,
            this.shouldUseVendorSearchWithCriteria(currentUser, searchRequest.searchQuery),
            searchRequest.searchQuery.state.siteNumber
        );

        return {
            pageResults: response.items,
            totalResultCount: response.count,
        };
    }

    exportData(): Promise<void> {
        throw new Error('Exporting not implemented for supplier search');
    }

    get key(): string {
        return 'VendorSearch';
    }

    private shouldUseVendorSearchWithCriteria(
        currentUser: IPortalUser,
        searchQuery: SimpleFieldRendererViewModel<IVendorSearchData>
    ) {
        return (
            !searchQuery.state.includeConnectedVendorsOnly && currentUser.extraFeatures.has('VendorManagement.Enabled')
        );
    }
}

export const useVendorSearchService = createUseSearchService(VendorSearchService);
