import axios from 'axios';
import { saveAs } from 'file-saver';

export const getFileFromBucket = (filePath: string, fileName: string) => {
    const url = `${window.location.protocol}//${window.location.hostname}/${filePath}${fileName}`;
    window.open(url, '_blank');
};

export const downloadServerFile = async (filepath: string, filename: string, type?: string) => {
    const api = axios.create();
    const resp = await api.get(filepath);
    const blob = new Blob([resp.data], { type: type ?? 'text/plain;charset=utf-8' });
    saveAs(blob, filename);
};

export const downloadLocalFile = async (filepath: string, filename: string, type?: string) => {
    const blob = new Blob([filepath], { type: type ?? 'text/plain;charset=utf-8' });
    saveAs(blob, filename);
};

export const base64ToBlob = (base64: string, contentType: string) => {
    // decode base64 string, remove space for IE compatibility
    const binary = atob(base64.replace(/\s/g, ''));
    const len = binary.length;
    const buffer = new ArrayBuffer(len);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < len; i++) {
        view[i] = binary.charCodeAt(i);
    }

    // create the blob object with passed contentType
    const blob = new Blob([view], { type: contentType });
    return blob;
};

export interface IFileViewModel {
    FileContentType: string;
    FileData: string;
    FileName: string;
    FileSize: number;
}

export const downloadFromFileViewModel = (fileViewModel: IFileViewModel) => {
    const blob = base64ToBlob(fileViewModel.FileData, fileViewModel.FileContentType);
    saveAs(blob, fileViewModel.FileName);
};
