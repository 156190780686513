import { ChangeEvent, FC, FocusEventHandler, KeyboardEventHandler } from 'react';
import {
    BaseTextFieldProps,
    Box,
    BoxProps,
    FormControl,
    InputBaseComponentProps,
    InputLabelProps,
    StandardTextFieldProps,
    TextField as MuiTextField,
    SxProps,
    Theme,
} from '@mui/material';
import { ReadOnly } from '../ReadOnly';
import { useFieldsReadonly } from '../Form';
import { hideTooltipIconWhenLabelNotFloating, ITooltipIconProps, renderTooltipLabel } from '../Tooltip';

export type TextFieldSize = 'small' | 'medium' | undefined;

export type TextFieldColor = 'primary' | 'secondary' | undefined;

export interface ITextFieldProps {
    id?: string;
    label?: string;
    name?: string;
    multiline?: boolean;
    rows?: string | number;
    value?: string;
    color?: TextFieldColor;
    disabled?: boolean;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    readonly?: boolean;
    error?: boolean;
    type?: string;
    size?: TextFieldSize;
    onBlur?: FocusEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined;
    onFocus?: FocusEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined;
    onKeyDown?: KeyboardEventHandler<HTMLDivElement> | undefined;
    onKeyUp?: KeyboardEventHandler<HTMLDivElement> | undefined;
    testId?: string;
    inputRef?: BaseTextFieldProps['inputRef'];
    sx?: BaseTextFieldProps['sx'];
    required?: boolean;
    helperText?: BaseTextFieldProps['helperText'];
    onDoubleClick?: BaseTextFieldProps['onDoubleClick'];
    tooltip?: ITooltipIconProps;
    autoComplete?: string;
    InputProps?: StandardTextFieldProps['InputProps'];
    placeholder?: string;
    inputLabelProps?: InputLabelProps;
    stacked?: boolean;
    labelBoxSx?: BoxProps['sx'];
    containerSx?: BoxProps['sx'];
    inputProps?: InputBaseComponentProps;
    textFieldLabel?: string;
    numericOnly?: boolean;
    readonlySx?: SxProps<Theme>;
}

const getSizeSX = (size: TextFieldSize): BaseTextFieldProps['sx'] => {
    if (size === 'small') {
        return {
            '.MuiOutlinedInput-input': {
                padding: '8px 14.5px !important',
                height: '1.4375em !important',
            },
        };
    }
    return {};
};

const getColorSX = (color: TextFieldColor): BaseTextFieldProps['sx'] => {
    if (color === 'secondary') {
        return {
            '.MuiInputLabel-root.Mui-focused, .MuiInputLabel-root.MuiFormLabel-filled': {
                color: 'white',
            },
            '.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'white',
            },
        };
    }
    return {};
};

const getSX = (size: TextFieldSize, color: TextFieldColor, sx: BaseTextFieldProps['sx']): BaseTextFieldProps['sx'] => {
    return {
        ...getSizeSX(size),
        ...getColorSX(color),
        ...hideTooltipIconWhenLabelNotFloating,
        '>.MuiFormLabel-root:not(.MuiFormLabel-filled):not(.Mui-focused)': { top: -9 },
        ...(Array.isArray(sx) ? sx : [sx]),
    };
};

export const TextField: FC<ITextFieldProps> = ({
    id,
    label,
    name,
    multiline,
    rows,
    value,
    color,
    disabled,
    onChange,
    readonly,
    error,
    type,
    size,
    onBlur,
    onFocus,
    onKeyDown,
    onKeyUp,
    testId,
    inputRef,
    sx,
    required,
    helperText,
    onDoubleClick,
    tooltip,
    autoComplete,
    InputProps,
    placeholder,
    inputLabelProps,
    stacked,
    labelBoxSx,
    inputProps,
    textFieldLabel,
    containerSx,
    numericOnly = false,
    readonlySx,
}) => {
    const isReadonly = useFieldsReadonly(readonly);
    if (isReadonly) {
        return <ReadOnly testId={testId} label={label} value={value} sx={readonlySx} />;
    }

    if (required && label) {
        label = `${label} *`;
    }

    const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        if (onChange === undefined) {
            return;
        }
        if (numericOnly) {
            const inputValue = event.target.value;
            if (inputValue === '' || !isNaN(Number(inputValue))) {
                onChange(event);
            }
        } else {
            onChange(event);
        }
    };

    return (
        <Box
            sx={{
                ...(stacked
                    ? { display: 'flex', flexDirection: 'column', width: '100%' }
                    : { display: 'flex', alignItems: 'center', width: '100%' }),
                ...containerSx,
            }}
        >
            {label ? (
                <Box component="label" htmlFor={id}>
                    <Box
                        sx={{
                            width: !stacked ? 135 : 160,
                            marginRight: !stacked ? '25px' : '',
                            ...labelBoxSx,
                        }}
                    >
                        {renderTooltipLabel({ tooltip, label })}
                    </Box>
                </Box>
            ) : null}
            <FormControl fullWidth={true}>
                <MuiTextField
                    id={id}
                    name={name}
                    variant="outlined"
                    multiline={multiline}
                    rows={rows}
                    value={value}
                    disabled={disabled}
                    onChange={onChangeHandler}
                    error={error}
                    type={type}
                    fullWidth={true}
                    size={size}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    onKeyDown={onKeyDown}
                    onKeyUp={onKeyUp}
                    sx={getSX(size, color, sx)}
                    inputProps={{ 'data-testid': testId, ...inputProps }}
                    inputRef={inputRef}
                    required={required}
                    helperText={helperText}
                    onDoubleClick={onDoubleClick}
                    InputLabelProps={{
                        sx: {
                            display: 'flex',
                        },
                        ...inputLabelProps,
                    }}
                    autoComplete={autoComplete}
                    InputProps={InputProps}
                    placeholder={placeholder}
                    label={textFieldLabel}
                />
            </FormControl>
        </Box>
    );
};
