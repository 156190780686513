import { isExternal } from '../../utils';
import { ILeafMenuItem } from './types';
import { NavLink } from 'react-router-dom';
import { ListItem, ListItemText, ListItemButton } from '@mui/material';
import { OpenInNew } from '@mui/icons-material';
import { FCNC } from '../FCNC';

interface IHeaderMenuItemProps {
    menuItem: ILeafMenuItem;
    paddingLeft?: number;
    isSelected: boolean;
}

export const HeaderMenuItem: FCNC<IHeaderMenuItemProps> = ({ menuItem, paddingLeft, isSelected }) => {
    if (isExternal(menuItem.path)) {
        return (
            <ListItem
                sx={{ px: 0, py: 0, pl: paddingLeft }}
                data-testid={`menu-option-${menuItem.testId ?? menuItem.name}`}
            >
                <ListItemButton component="a" href={menuItem.path} target="_blank" sx={{ color: '#fff' }}>
                    <ListItemText
                        primary={
                            <>
                                {menuItem.name}
                                <OpenInNew sx={{ fontSize: 14 }} />
                            </>
                        }
                    />
                </ListItemButton>
            </ListItem>
        );
    }
    return (
        <ListItem
            sx={{ px: 0, py: 0, pl: paddingLeft }}
            data-testid={`menu-option-${menuItem.testId ?? menuItem.name}`}
        >
            <ListItemButton
                component={NavLink}
                to={menuItem.path}
                sx={isSelected ? { borderRight: 6, borderColor: 'primary.400', borderStyle: 'solid' } : undefined}
            >
                <ListItemText primary={menuItem.name} />
            </ListItemButton>
        </ListItem>
    );
};
