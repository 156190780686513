import { QueryClient } from '@tanstack/react-query';
import { CompanyThunk } from '../../../../transcepta-thunks';
import mapCompanyCustomData from './mapCompanyCustomData';
import mapCompanyGLAccount from './mapCompanyGLAccount';
import { ISegment } from '../types';

const queryOptions = {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 24 * 60 * 60 * 1000, // 24 hours
    cacheTime: 24 * 60 * 60 * 1000, // 24 hours
};

export async function fetchCompanyCustomData(params) {
    const companyThunk = new CompanyThunk();
    const response = await companyThunk.fetchCompanyCustomDataSet(params);
    if ('type' in response) {
        throw new Error(response.text);
    }
    const options = mapCompanyCustomData(response.data, params.datasetValuePath);
    return options;
}

export async function fetchCompanyGLAccounts(params) {
    const companyThunk = new CompanyThunk();
    const response = await companyThunk.fetchCompanyGLAccounts(params);
    if ('type' in response) {
        throw new Error(response.text);
    }
    const options = response.data.map((d) => mapCompanyGLAccount(d));
    return options;
}

export function createFetchCompanyCustomDataArgs(
    datasetName: string | undefined,
    datasetPath: string | undefined,
    companyId: number
) {
    return [
        ['GLCompanyCustomDataInput', datasetName, datasetPath, companyId],
        () =>
            fetchCompanyCustomData({
                companyId,
                datasetName,
                datasetValuePath: datasetPath,
            }),
        queryOptions,
    ] as const;
}

export function createFetchCompanyGLAccountsDataArgs(seg: ISegment, companyId: number) {
    return [
        ['GLCompanyGLAccountsDataInput', seg.DataType, companyId],
        () => fetchCompanyGLAccounts({ companyId }),
        queryOptions,
    ] as const;
}

export interface IGLCodeDataType {
    CompanyGLAccount: string;
    CompanyCustomData: string;
}

export function prefetchGLSegmentData(
    queryClient: QueryClient,
    glCodeDataType: IGLCodeDataType,
    glSegments: ISegment[],
    companyId: number
) {
    glSegments.forEach((seg) => {
        if (seg.DataType === glCodeDataType.CompanyCustomData && !seg.CompanyCustomDatasetFieldDefinitionKey) {
            queryClient.prefetchQuery(
                ...createFetchCompanyCustomDataArgs(
                    seg.CompanyCustomDatasetName,
                    seg.CompanyCustomDatasetPath,
                    companyId
                )
            );
        }

        if (seg.DataType === glCodeDataType.CompanyGLAccount) {
            queryClient.prefetchQuery(...createFetchCompanyGLAccountsDataArgs(seg, companyId));
        }
    });
}
