import { DotNotationFieldRendererViewModel } from '../../../reusableFeatures';
import { IVendor, IVendorData, IVendorSearchGrid, IVendorSearchGridItem } from './types';
import { VendorApi, IVendorViewModel } from '../Api';
import { CompanyApi, ITradingPartnerSearchViewModel } from '../../Company/Api';
import { CompanyService } from '../../Company/Services';
import { portalUserService } from '../../UserService';
import { GridSortModel } from '@mui/x-data-grid';
import { orderBy } from '../../utils/orderBy';
import { calculatePageOffset } from '../../../utils';
import { validateData } from '../../../ui';

function convertToVendor(data: IVendorViewModel): IVendor {
    return {
        id: data.Id,
        vendorNumber: data.VendorNumber ?? '',
        vendorName: data.VendorName ?? '',
        vendorClass: data.VendorClass,
        active: data.Active,
        duns: data.DUNS ?? '',
        annualVolume: data.AnnualVolume,
        annualSpend: data.AnnualSpend,
        taxIdentifier: data.TaxIdentifier ?? '',
        taxIdentifierAuthority: data.TaxIdentifierAuthority ?? '',
        attributes: DotNotationFieldRendererViewModel.fromXMLBasedObject(data.Attributes, {}),
    };
}

function convertToVendorViewModel(data: IVendorData): Partial<IVendorViewModel> {
    return {
        VendorNumber: data.vendorNumber,
        VendorName: data.vendorName,
        VendorClass: data.vendorClass,
        Active: data.active,
        DUNS: data.duns,
        AnnualVolume: Number(data.annualVolume),
        AnnualSpend: Number(data.annualSpend),
        TaxIdentifier: data.taxIdentifier,
        TaxIdentifierAuthority: data.taxIdentifierAuthority,
        Attributes: data.attributes.state,
    };
}

export class VendorService {
    private api = new VendorApi();

    private companyApi = new CompanyApi();

    private companyService = new CompanyService();

    public getVendor = async (vendorId: number): Promise<IVendor | null> => {
        const response = await this.api.getVendor(vendorId);

        if (!response.data) {
            return null;
        }

        return convertToVendor(response.data);
    };

    private validateVendor = async (vendor: IVendorData) => {
        const vendorClasses = await this.companyService.getVendorClassNames(portalUserService.getCurrentCompanyId());

        validateData((errors) => {
            if (!vendor.vendorNumber) {
                errors.push('Vendor Number is required');
            }
            if (!vendor.vendorName) {
                errors.push('Vendor Name is required');
            }
            if (!vendor.vendorClass && vendorClasses.length > 0) {
                errors.push('Vendor Class is required');
            }
        });
    };

    public updateVendor = async (vendorId: number, vendor: IVendorData): Promise<void> => {
        await this.validateVendor(vendor);
        await this.api.updateVendor(vendorId, convertToVendorViewModel(vendor));
    };

    public createVendor = async (vendor: IVendorData): Promise<number> => {
        await this.validateVendor(vendor);
        const companyId = portalUserService.getCurrentCompanyId();

        if (!companyId) {
            throw new Error('Cannot create vendor without company id');
        }

        const response = await this.api.createVendor({
            // Do not remove hardcoded params, these are needed for the API to work...
            ExcludeFromDynamicDiscounting: 1,
            MaxEmailSize: 1024,
            CompanyId: companyId,
            ...convertToVendorViewModel(vendor),
        });

        return response.data.IDs[0];
    };

    public getVendorSearchGrid = async (
        companyId: number,
        count: boolean,
        pageSize: number,
        page: number,
        sortModel: GridSortModel,
        name: string,
        contactName: string,
        contactEmail: string,
        vendorNumber: string,
        vendorClass: string | null,
        includeDisabledSuppliers: boolean,
        includeVendorTable: boolean,
        siteNumber: string
    ): Promise<IVendorSearchGrid> => {
        const originalField: { [key: string]: string | undefined } = {
            supplierId: 'CompanyID',
            vendorId: 'VendorId',
            name: 'Name',
            vendorNumber: 'VendorNumber',
            vendorClass: 'VendorClass',
            contactName: 'ContactName',
            contactEmail: 'ContactEmail',
            siteNumber: 'VendorSiteNumber',
        };

        const response = await this.companyApi.getTradingPartnersByCompanyId(
            companyId,
            count,
            pageSize,
            calculatePageOffset(pageSize, page),
            orderBy(
                sortModel.map(({ field, sort }) => ({
                    field: originalField[field] ?? field,
                    sort,
                }))
            ),
            name,
            contactName,
            contactEmail,
            vendorNumber,
            vendorClass,
            includeDisabledSuppliers,
            includeVendorTable,
            includeVendorTable ? siteNumber : null
        );

        const transformItem = (item: ITradingPartnerSearchViewModel) => {
            if (!item.CompanyID && !item.VendorId) {
                throw new Error(`Expected either vendor or supplier id to be non-null: ${JSON.stringify(item)}`);
            }

            const transformedItem: IVendorSearchGridItem = {
                id: `${item.CompanyID}-${item.VendorId}-${item.VendorSiteNumber}`,
                detailsId: `${item.CompanyID}-${item.VendorId}`,
                name: item.Name ?? '',
                vendorNumber: item.VendorNumber,
                vendorClass: item.VendorClass,
                contactName: item.ContactName,
                contactEmail: item.ContactEmail,
                siteNumber: item.VendorSiteNumber,
                invoiceProfileType: item.InvoiceProfileType,
            };

            return transformedItem;
        };

        return {
            count: response.data.Count,
            items: response.data.Items.map(transformItem),
        };
    };

    static defaultCRUDFields: IVendorData = {
        vendorNumber: '',
        vendorName: '',
        vendorClass: null,
        active: false,
        duns: '',
        annualVolume: null,
        annualSpend: null,
        taxIdentifier: '',
        taxIdentifierAuthority: '',
        attributes: DotNotationFieldRendererViewModel.fromXMLBasedObject(null, {}),
    };
}
