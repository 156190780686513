import { saveAs } from 'file-saver';
import {
    createUseSearchService,
    DependenciesWatcher,
    ISearchConfiguration,
    ISearchRequest,
    ISearchResponse,
    ISearchService,
    SearchCriteriaError,
} from '../../../reusableFeatures';
import { backendServices, Currency, orderBy, portalUserService } from '../../../services';
import { calculatePageOffset } from '../../../utils';
import { BusinessDocType } from '../../../types';
import { DocumentSearchConfigurationUtils } from '../..';
import { DocumentSearchConfigurationPage } from '../../DocumentSearchConfiguration';

export class DocumentsSearchService implements ISearchService<DocumentSearchConfigurationUtils.FilterValues> {
    private documentApi = new backendServices.Apis.DocumentApi();

    private getCurrentConfiguration(vm: backendServices.ViewModels.DocumentSearchConfigurationViewModel[]) {
        return vm.find((conf) => conf.ScreenSize === this.layoutSize)!;
    }

    constructor(
        private readonly businessDocType: BusinessDocType,
        private readonly isBuyerPortal: boolean,
        private readonly layoutSize: backendServices.ViewModels.FieldDictionaryScreenSize,
        private readonly currencyList: Currency[],
        private readonly dependenciesWatcher: DependenciesWatcher,
        private readonly page: number,
        private readonly onFetchSearchConfigurationSuccess: (
            config: backendServices.ViewModels.DocumentSearchConfigurationViewModel
        ) => void
    ) {}

    async fetchSearchConfiguration(): Promise<ISearchConfiguration<DocumentSearchConfigurationUtils.FilterValues>> {
        const [, { data: configurationVM }] = await Promise.all([
            this.dependenciesWatcher.waitForDependencies(),
            this.documentApi.getDocumentSearchConfiguration({
                businessDocType: this.businessDocType,
                isBuyerPortal: this.isBuyerPortal,
                page: this.page,
                workflowTemplateUI: null,
            }),
        ]);
        const currentConfiguration = this.getCurrentConfiguration(configurationVM);

        this.onFetchSearchConfigurationSuccess(currentConfiguration);

        const searchFormFieldColumns = DocumentSearchConfigurationUtils.getSearchFormFieldColumns(currentConfiguration);

        const gridColumns = DocumentSearchConfigurationUtils.getGridColumns({
            configuration: currentConfiguration,
            currencyList: this.currencyList,
            /**
             * This is fine, {users} and {userGroups} are only used in parking lot
             */
            users: [],
            userGroups: [],
        });

        return {
            gridColumns,
            enabledExportingMethods: [],
            searchFormFieldColumns,
            pageSizeOptions: [10, 25, 50],
            defaultPageSize: 10,
            searchWithNoCriteria: false,
            initialSort: [
                {
                    field:
                        this.page === DocumentSearchConfigurationPage.INVOICES_REJECTED
                            ? DocumentSearchConfigurationUtils.constants.SENDER_CONFIRMATION_FIELD_KEY
                            : this.page === DocumentSearchConfigurationPage.INVOICES_DELIVERED
                            ? DocumentSearchConfigurationUtils.constants.DISPATCHED_TIME_FIELD_KEY
                            : DocumentSearchConfigurationUtils.constants.CREATED_TIME_FIELD_KEY,
                    sort: 'desc',
                },
            ],
            viewModel: configurationVM,
        };
    }

    async fetchResults(
        searchConfiguration: ISearchConfiguration<
            DocumentSearchConfigurationUtils.FilterValues,
            backendServices.ViewModels.DocumentSearchConfigurationViewModel[]
        >,
        searchRequest: ISearchRequest<DocumentSearchConfigurationUtils.FilterValues>
    ): Promise<ISearchResponse> {
        DocumentSearchConfigurationUtils.removeSuffixForDupesFromSearchRequest(searchRequest);

        const currentConfiguration = this.getCurrentConfiguration(searchConfiguration.viewModel!);

        const { $filter, dynamicParams } = DocumentSearchConfigurationUtils.getSearchFilterAndParams(
            searchRequest,
            currentConfiguration,
            this.businessDocType,
            []
        );

        try {
            const { data } = await this.documentApi.getDocuments(
                {
                    $count: true,
                    $filter,
                    $skip: calculatePageOffset(searchRequest.pageSize, searchRequest.pageNumber),
                    $top: searchRequest.pageSize,
                    companyID: portalUserService.getCurrentCompanyId(),
                    $orderby: orderBy(searchRequest.sort),
                    isBuyer: this.isBuyerPortal,
                },
                dynamicParams
            );

            return {
                pageResults: data.Items.map((item) => ({ id: `${item.Id}-${item.DocumentID}`, ...item })),
                totalResultCount: data.Count ?? 0,
            };
        } catch (error: any) {
            console.error(error);
            if (error?.response?.status >= 400 && error?.response?.status <= 599) {
                throw new SearchCriteriaError(
                    'Network error. Please wait a few minutes and try again. If you continue to receive this error please contact Transcepta Support at support@transcepta.com.'
                );
            }

            throw error;
        }
    }

    async exportData(
        searchConfiguration: ISearchConfiguration<
            DocumentSearchConfigurationUtils.FilterValues,
            backendServices.ViewModels.DocumentSearchConfigurationViewModel[]
        >,
        searchRequest: ISearchRequest<DocumentSearchConfigurationUtils.FilterValues>,
        _exportMethod: string,
        filename: string
    ) {
        DocumentSearchConfigurationUtils.removeSuffixForDupesFromSearchRequest(searchRequest);

        const currentConfiguration = this.getCurrentConfiguration(searchConfiguration.viewModel!);

        const { $filter, dynamicParams } = DocumentSearchConfigurationUtils.getSearchFilterAndParams(
            searchRequest,
            currentConfiguration,
            this.businessDocType,
            []
        );

        const { data } = await this.documentApi.getDocumentsCSV(
            {
                $filter,
                companyID: portalUserService.getCurrentCompanyId(),
                $orderby: orderBy(searchRequest.sort),
                isBuyer: this.isBuyerPortal,
            },
            dynamicParams
        );

        const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, filename);
    }

    get key(): string {
        const composedKey = [
            'DocumentSearchPage',
            this.businessDocType.toString(),
            this.layoutSize.toString(),
            this.page,
        ];

        return composedKey.join();
    }
}

export const useDocumentsSearchService = createUseSearchService(DocumentsSearchService);
