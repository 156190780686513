import { SeriesLineOptions } from 'highcharts';
import { DashboardApi, WorkflowDocument } from '../../../Api';
import { DashboardDataPayload } from '../../../Api/DashboardDataPayload';
import { createDashboardReportService } from '../../createDashboardReportService';
import { DashboardReportService } from '../../DashboardReportService';
import { dashboardFormatDate } from '../../../Charts/Chart/Chart.utils';

export class StraightWorkflowDocumentReportService
    implements DashboardReportService<WorkflowDocument, SeriesLineOptions['data']>
{
    private api = new DashboardApi();

    get storedProcedure() {
        return 'WorkflowDocumentsDashboardsReport' as const;
    }

    async fetchReport(settings: DashboardDataPayload) {
        const res = await this.api.getDashboardReportData<WorkflowDocument>(settings, this.storedProcedure);
        return res.data;
    }

    get nullifyDataIfEmptyObj() {
        return {
            optIn: true,
            xAxisPropertyKey: 'Date' as const,
        };
    }

    transformVMToChartData(vm: WorkflowDocument['viewModel']) {
        return vm.map((p) => ({
            y: p.WorkflowDocumentCount,
            name: dashboardFormatDate(p.Date),
            label: dashboardFormatDate(p.Date),
        }));
    }

    async exportCSV(settings: DashboardDataPayload) {
        return this.api.exportDashboardReport(settings, this.storedProcedure);
    }
}

export const useStraightWorkflowDocumentReportService = createDashboardReportService(
    StraightWorkflowDocumentReportService
);
