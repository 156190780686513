import { RecursivePartial } from '../../../types';
import { CompanyApi } from '../../Company/Api';
import { portalUserService } from '../../UserService';
import { IUserViewModel, UserApi } from '../../UserService/Api';
import { UserGroupApi } from '../Api/UserGroupApi';
import { IUserGroupViewModel } from '../Api/ViewModels';
import { IUserGroup, IUserGroupData, IUserGroupGrid, IUserGroupViewModelData } from './types';
import { IUserGroupMembershipListUser } from './types/UserGroupMembershipListItem';

export class UserGroupService {
    api = new UserGroupApi();

    userApi = new UserApi();

    companyApi = new CompanyApi();

    public getUserGroupsGrid = async (companyId: number, sort?: string): Promise<IUserGroupGrid[] | undefined> => {
        const userGroups = await this.api.getUserGroups(companyId, sort);

        const data = userGroups.data;

        const newGridData: IUserGroupGrid[] = data.map((value) => {
            return {
                id: value.Id ?? -1,
                name: value.Name,
                description: value.Description,
                parentUserGroupName: '',
                parentUserGroupId: value.ParentUserGroupId,
                actions: 'Delete',
            };
        });

        const map = new Map<number, IUserGroupGrid>(newGridData.map((item) => [item.id, item]));

        const getParentName = (userGroup: IUserGroupGrid) => {
            if (userGroup.parentUserGroupId && map.has(userGroup.parentUserGroupId)) {
                return map.get(userGroup.parentUserGroupId)?.name;
            }
            return '';
        };

        const withParentNames = newGridData.map((userGroup) => ({
            ...userGroup,
            parentUserGroupName: getParentName(userGroup) as string,
        }));

        return withParentNames;
    };

    public getCompanyEnabledWorkflows = async () => {
        const companyId = portalUserService.getCurrentCompanyId();
        const response = await this.companyApi.getBuyerPortalSettings(companyId);
        return response?.data?.WorkflowsEnabled.map((x) => ({ Id: x.Id, Active: x.Active, ShortName: x.ShortName }));
    };

    private toUserGroup = (viewModel: IUserGroupViewModel) => {
        const userGroup: IUserGroup = {
            id: viewModel.Id,
            name: viewModel.Name,
            description: viewModel.Description,
            parentUserGroupId: viewModel.ParentUserGroupId,
            control: viewModel.Control,
            usage: viewModel.Usage,
            controlUS: viewModel.Control_US,
            usageUS: viewModel.Usage_US,
            workflowUserGroups: viewModel.WorkflowUserGroups.map((x) => ({ workflowId: x.WorkflowId })),
            userGroupMemberships: viewModel.UserGroupMemberships.map((x) => ({
                id: x.Id.toString(),
                userId: x.UserId,
            })),
        };
        return userGroup;
    };

    private toViewModel(userGroupFields: IUserGroupData) {
        const companyId = portalUserService.getCurrentCompanyId();

        const userGroupViewModel: RecursivePartial<IUserGroupViewModelData> = {
            Name: userGroupFields.name,
            Description: userGroupFields.description,
            ParentUserGroupId: userGroupFields.parentUserGroupId,
            Usage: userGroupFields.usage,
            Control: userGroupFields.control,
            CompanyId: companyId,
            WorkflowUserGroups: userGroupFields.workflowUserGroups.map((x) => ({ WorkflowId: x.workflowId })),
            UserGroupMemberships: userGroupFields.userGroupMemberships.map((x) => ({
                UserId: x.userId,
            })),
        };

        return userGroupViewModel;
    }

    public getUserGroup = async (id: number, filter?: string): Promise<IUserGroup> => {
        const response = await this.api.getUserGroup(id, filter);
        const data = response.data;

        if (!data.length) {
            throw new Error('User group not found.');
        }

        return this.toUserGroup(data[0]);
    };

    public getUserGroups = async (companyId: number): Promise<IUserGroup[]> => {
        const response = await this.api.getUserGroups(companyId);
        const data = response.data;
        return data.map((value) => this.toUserGroup(value));
    };

    public deleteUserGroup = async (id: number) => {
        await this.api.deleteUserGroup(id);
    };

    public updateUserGroup = async (userGroupId: number, userGroup: IUserGroupData): Promise<void> => {
        await this.api.updateUserGroup(userGroupId, this.toViewModel(userGroup));
    };

    public createUserGroup = async (userGroup: IUserGroupData): Promise<number> => {
        const response = await this.api.createUserGroup(this.toViewModel(userGroup));
        return response.data.ID;
    };

    public getUsers = async (companyId: number) => {
        const response = await this.userApi.getUsers({ companyId });
        return [...response.data.Items.map((value) => this.toUser(value))];
    };

    private toUser(userViewModel: IUserViewModel): IUserGroupMembershipListUser {
        return { name: userViewModel.Name, id: userViewModel.ID };
    }
}
