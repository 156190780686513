export const featureFlags = [
    // these are long-term code refactors that are ongoing
    'XMLParserSwitch', // we are trying to migrate from 'xml2js' to 'fastxml', but getting an issue with different structures

    'ValidateSamplesPhase2', // for backlog item #15368 - future enhancements to #12185 that have been partially started

    // big future release items - remove feature flags after these are released to production
    'APIBasedInvoiceFlips', // backlog item #14807
    'UserGroupsRewrite', // backlog item #16990
    'PasswordChangeRewrite', // backlog item #16936
    'DocumentTotalsCalculationsV2', // backlog item #17457
    'HelpContent', // backlog item #17458
] as const;
