import { History, Location } from 'history';
import { useHistory, useLocation } from 'react-router';
import { useEffect, useState } from 'react';
import { ISearchRequest } from '../types';
import { range } from '../../../utils';
import { SimpleFieldRendererViewModel } from '../../FieldRenderer';
import { initialSearchFormState } from './searchFormState';
import { filterStorageUtils } from './filterStorage.utils';

type Value = string | number | boolean | Date;

const arraySeparator = String.fromCharCode(30);
/**
 * We store the data type of each value in the URL param value. This converts the encoded
 * value to its original value of the correct type.
 * @param value the encoded value
 * @returns the decoded value with correct data type
 */
function decodeTypedValue(value: string): Value | Value[] {
    if (value.startsWith('s')) {
        return value.substring(1);
    }

    if (value.startsWith('n')) {
        return Number.parseInt(value.substring(1), 10);
    }

    if (value.startsWith('d')) {
        return new Date(+value.substring(1));
    }

    if (value.startsWith('a')) {
        return value
            .substring(1)
            .split(arraySeparator)
            .map((v) => decodeTypedValue(v)) as Value[];
    }

    return value.substring(1) === 'true';
}

/**
 * Encodes the data type of a primitive value and the value in a string.
 * @param value the value to encode type information for
 * @returns the encoded value/type as a string
 */
function encodeTypeInValue(value: Value): string {
    if (typeof value === 'string') {
        return `s${value}`;
    }

    if (typeof value === 'number') {
        return `n${value}`;
    }

    if (value instanceof Date) {
        return `d${value.getTime()}`;
    }

    if (Array.isArray(value)) {
        return `a${value.map((v) => encodeTypeInValue(v)).join(arraySeparator)}`;
    }

    return `b${value}`;
}

/**
 * Converts string url params to an object of url params, decoding typed values
 * @param urlParams the string representation of the url params
 * @returns the object representation of the url params
 */
function fromUrlParams(urlParams: string): object {
    const params = urlParams.split('&');
    const obj: any = {};

    params.forEach((param) => {
        const [k, v] = param.split('=');
        obj[decodeURIComponent(k)] = decodeTypedValue(decodeURIComponent(v));
    });

    return obj;
}

/**
 * Checks if the value is "non-empty". This allows us to elide empty values from
 * the URL params, saving space and helping create a canonical representation of
 * different states.
 *
 * Any "empty value" properties will get their state from the defaults when restored from the URL
 * later.
 *
 * @param value the value to check for "non-emptiness"
 * @returns whether the value is non-empty
 */
function isNonEmptyValue(value: string | number | boolean | undefined) {
    return !!value;
}

/**
 * Convert url params object to a string representation, encoding data types of values
 * @param urlParamsObj the object of url params
 * @returns the encoded url params string
 */
export function toUrlParams(urlParamsObj: object, defaultPageSize: number) {
    return Object.keys(urlParamsObj)
        .filter((k) => {
            switch (true) {
                case typeof (urlParamsObj as any)[k] === 'boolean':
                case typeof (urlParamsObj as any)[k] === 'number':
                    return true;
                case Array.isArray((urlParamsObj as any)[k]):
                    return (urlParamsObj as any)[k]?.length > 0;
                default:
                    return isNonEmptyValue((urlParamsObj as any)[k]);
            }
        })
        .filter((k) => k !== 'pageSize' || (urlParamsObj as any).pageSize !== defaultPageSize)
        .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(encodeTypeInValue((urlParamsObj as any)[k]))}`)
        .join('&');
}

/**
 * Converts a search request object to a url params object.
 * @param searchRequest the search request
 * @returns the url params object.
 */
function fromSearchRequest(searchRequest: ISearchRequest<any>): object {
    const obj: any = {};

    obj.pageSize = searchRequest.pageSize;
    obj.pageNumber = searchRequest.pageNumber;

    Object.keys(searchRequest.searchQuery.state).forEach((key) => {
        if (key !== 'dropzone') {
            obj[`q${key}`] = searchRequest.searchQuery.state[key];
        }
    });

    searchRequest.sort.forEach((sortItem, i) => {
        obj[`s${i}f`] = sortItem.field;
        obj[`s${i}d`] = sortItem.sort;
    });

    return obj;
}

/**
 * Restores a search request from a url params object.
 * @param obj url params object
 * @returns the search request
 */
function toSearchRequest(obj: any) {
    const searchQuery: any = {};
    const sortItems: any = {};

    Object.keys(obj).forEach((key) => {
        if (key.startsWith('q')) {
            searchQuery[key.substring(1)] = obj[key];
        }

        if (key.startsWith('s')) {
            const index = key[1];
            const type = key[2] === 'f' ? 'field' : 'sort';

            if (!sortItems[index]) {
                sortItems[index] = {
                    field: '',
                    sort: null,
                };
            }

            sortItems[index][type] = obj[key];
        }
    });

    const sortItemMaxKey = Math.max(-1, ...Object.keys(sortItems).map((x) => Number.parseInt(x, 10)));

    const sort = range(sortItemMaxKey + 1).map((_, i) => sortItems[i]);

    return {
        sort,
        searchQuery,
        pageSize: (obj.pageSize as number | undefined) ?? initialSearchFormState.pageSize,
        pageNumber: (obj.pageNumber as number | undefined) ?? initialSearchFormState.page,
    };
}

/**
 * Encodes a search request as a url params string
 * @param searchRequest the search request
 * @returns the encoded url params string
 */
function encodeSearchRequest(searchRequest: ISearchRequest<any>, defaultPageSize: number) {
    return toUrlParams(fromSearchRequest(searchRequest), defaultPageSize);
}

/**
 * Decodes a url params string to a search request
 * @param urlParams the url params string
 * @returns the search request
 */
function decodeSearchRequest(urlParams: string) {
    return toSearchRequest(fromUrlParams(urlParams));
}

function getCriteria(history: History, location: Location, restoreFiltersFromSessionStorage: boolean = false) {
    const searchInSessionStorage = filterStorageUtils.getFiltersByPathname(location.pathname) ?? '?';
    const searchInUrl = history.location.search;

    const shouldUseSearchFromSessionStorage =
        restoreFiltersFromSessionStorage && searchInSessionStorage.length > 1 && !searchInUrl.length;

    const search = shouldUseSearchFromSessionStorage ? searchInSessionStorage : searchInUrl || '?';

    const urlParams = search.substring(1);

    const request = decodeSearchRequest(urlParams);

    //Gets the dropzone object from the location state
    if (location.state) {
        request.searchQuery = {
            ...request.searchQuery,
            dropzone: location.state,
        };
    }

    // we need a stable reference so the caller can use this value as a useEffect dependency
    return {
        enteredCriteria: new SimpleFieldRendererViewModel(request.searchQuery),
        appliedCriteria: new SimpleFieldRendererViewModel(request.searchQuery),
        sortModel: request.sort,
        page: request.pageNumber,
        pageSize: request.pageSize,
    };
}

/**
 * Returns search form state based on the current search criteria in the URL
 *
 * @returns the saved search form state
 */
export function useSavedSearchCriteria() {
    const history = useHistory();
    const location = useLocation<{ restoreFiltersFromSessionStorage?: boolean } | null>();
    /**
     * This can be used to opt-out of the session storage feature when redirecting to a search page.
     */
    const { restoreFiltersFromSessionStorage = true } = location.state ?? {};

    const [criteria, setCriteria] = useState(getCriteria(history, location, restoreFiltersFromSessionStorage));
    useEffect(() => {
        const seen = new Set<string | undefined>();

        history.listen(() => {
            // if this is the first time we've seen the history entry, then
            // the criteria was just saved to the URL, so we don't need to return
            // it as it is already what is applied on the page.
            setCriteria(getCriteria(history, location));
            seen.add(history.location.key);
        });
    }, [history, location]);
    return criteria;
}

/**
 * Saves the current search form state in the URL params.
 */
export function useSaveSearchCriteria(
    searchRequest: ISearchRequest<any>,
    dontUpdateUrl: boolean,
    defaultPageSize: number
) {
    const history = useHistory();

    const urlParams = encodeSearchRequest(searchRequest, defaultPageSize);

    const dropzone = searchRequest.searchQuery.state.dropzone;

    const location = useLocation<{ restoreFiltersFromSessionStorage?: boolean } | null>();
    /**
     * This can be used to opt-out of the session storage feature when redirecting to a search page.
     */
    const { restoreFiltersFromSessionStorage = true } = location.state ?? {};

    useEffect(() => {
        const search = urlParams && `?${urlParams}`;

        if (dontUpdateUrl) {
            return;
        }

        if (restoreFiltersFromSessionStorage) {
            filterStorageUtils.setFiltersByPathname(history.location.pathname, search);
        }

        // if the encoded search request hasn't changed, we don't want to push a new state
        // this will happen when the user "goes back" to a previous entry, because the state
        // that we encode will match the state loaded from the URL
        if (search === history.location.search && !dropzone) {
            return;
        }

        //Push the dropzone object to the history state
        history.replace(`${history.location.pathname}${search}`, dropzone);
    }, [history, urlParams, dontUpdateUrl, dropzone, restoreFiltersFromSessionStorage]);
}
