import { ExecutionContext } from '../../../../services/backendServices/ViewModels';
import { ValidationFunctionProgrammingError } from '../../../DocumentEditRules/DocumentEditValidator/errors';
import { ValidationFailureDictionary } from '../../../DocumentEditRules/types/ValidationFailureType';
import { Touchpoint } from '../../ScriptingEngineEnvironment';
import { DPSValidationResult } from './types';
import { VFTDisposition } from '../../../../services/ValidationFailureTypes/Api/ViewModels';

function cSharpFormat(formatString: string, replacements: any[]) {
    return formatString.replace(/{(\d+).*?}/g, (_, index) => {
        const value = replacements[index];

        if (value === undefined) {
            throw new Error(
                `Invalid replacements for format string. Format string required at least ${
                    parseInt(index, 10) + 1
                } replacements, but there are only ${replacements.length} replacements.`
            );
        }

        return value;
    });
}

function formatDefaultVFT(
    result: Touchpoint.DocumentValidation.ValidationResult,
    validationFailureDictionary: ValidationFailureDictionary
) {
    const { detailArguments, titleArguments } = result;

    const failureType = validationFailureDictionary[result.errorCode];

    if (!failureType) {
        console.error(
            'ErrorRenderer - Programming error occurred. An unknown ValidationError.errorCode was provided',
            result
        );
        throw new ValidationFunctionProgrammingError();
    }

    return {
        titleText: cSharpFormat(failureType.titleText, titleArguments),
        detailText: failureType.detailText ? cSharpFormat(failureType.detailText, detailArguments) : undefined,
    };
}

export function transformToDPSValidationResultErrors(
    results: Touchpoint.DocumentValidation.ValidationClassResult[],
    validationFailureDictionary?: ValidationFailureDictionary,
    context?: ExecutionContext
): DPSValidationResult[] {
    const errors = results.filter((x) => x.class === 'Error');

    const filteredErrors =
        context === ExecutionContext.WebEntry
            ? errors.filter(({ result }) => result.computedDisposition === VFTDisposition.ReturnToSupplier)
            : errors;

    return filteredErrors.map((x) => ({
        errorCode: x.result.errorCode,
        titleArguments: x.result.titleArguments,
        detailArguments: x.result.detailArguments,
        defaultVFT: validationFailureDictionary
            ? formatDefaultVFT(x.result, validationFailureDictionary)
            : x.result.defaultVFT ?? null,

        computedDisposition: x.result.computedDisposition,
    }));
}

export function transformToDPSValidationResultWarnings(
    results: Touchpoint.DocumentValidation.ValidationClassResult[],
    validationFailureDictionary?: ValidationFailureDictionary
): DPSValidationResult[] {
    const warnings = results.filter((x) => x.class === 'Warning');

    return warnings.map((x) => ({
        errorCode: x.result.errorCode,
        titleArguments: x.result.titleArguments,
        detailArguments: x.result.detailArguments,
        defaultVFT: validationFailureDictionary
            ? formatDefaultVFT(x.result, validationFailureDictionary)
            : x.result.defaultVFT ?? null,
        computedDisposition: x.result.computedDisposition,
    }));
}
