import { useQuery } from '@tanstack/react-query';
import { ExecutionService } from '../CustomJSRuleEngineV2';
import { BusinessDocType } from '../../types';

export function useValidationFailureDictionary({
    buyerCompanyId,
    businessDocType,
}: {
    buyerCompanyId?: number | null;
    businessDocType?: BusinessDocType | null;
}) {
    const shouldFetch = buyerCompanyId != null && businessDocType != null;

    const { data: validationFailureDictionary, isLoading } = useQuery(
        ['ValidationFailureDictionary', buyerCompanyId, businessDocType],
        async () => {
            const vfDictionary = await ExecutionService.DPSValidations.fetchValidationFailureTypes(
                buyerCompanyId!,
                businessDocType!
            );

            return vfDictionary;
        },
        {
            enabled: shouldFetch,
            staleTime: Infinity,
        }
    );

    return {
        validationFailureDictionary,
        isLoading,
    };
}
