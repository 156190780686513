import { AxiosResponse } from 'axios';
import { api } from '../../api';
import { ApiResponse, PageResult } from '../../backendServices/Utilities';
import { IUpdateInvoiceProfile, ProfileType } from '../Services';
import { ProfileViewModel } from '../../backendServices/ViewModels';

export class ProfileApi {
    route = '/Profile';

    private determineContentType(fileName: string) {
        const extensionStartIndex = fileName.lastIndexOf('.');
        if (extensionStartIndex >= 0) {
            const extension = fileName.substring(extensionStartIndex).toLowerCase();
            switch (extension) {
                case '.bmp':
                    return 'image/bmp';

                case '.gif':
                    return 'image/gif';

                case '.jfif':
                    return 'image/pipeg';

                case '.jpe':
                case '.jpeg':
                case '.jpg':
                    return 'image/jpeg';

                case '.png':
                    return 'image/png';

                default:
                    throw new Error('Unsupported file');
            }
        }
        throw new Error('Invalid file');
    }

    public updateProfile = async (
        id: number,
        data: IUpdateInvoiceProfile,
        selectedLogo?: File
    ): Promise<AxiosResponse<number>> => {
        let requestData;
        const formData = new FormData();
        if (selectedLogo && selectedLogo.name) {
            formData.append('Data', JSON.stringify(data));
            formData.append(
                'File',
                new Blob([selectedLogo as BlobPart], {
                    type: this.determineContentType(data.LogoPath ?? ''),
                }),
                selectedLogo?.name
            );
            requestData = formData;
        } else {
            requestData = data;
        }

        return api().put(`${this.route}/${id}`, requestData, {
            headers: { Accept: 'application/json', 'Content-Type': 'multipart/form-data' },
        });
    };

    public getProfile = async (id: number, profileType?: ProfileType): Promise<AxiosResponse<ProfileViewModel[]>> => {
        const params = {
            profileType,
        };

        return api().get(`${this.route}/${id}`, { params });
    };

    public searchProfiles = async (
        profileType: ProfileType,
        companyId?: number
    ): Promise<AxiosResponse<ProfileViewModel[]>> => {
        const params = {
            profileType,
            companyId,
        };

        return api().get(`${this.route}/Search`, { params });
    };

    public searchProfilesPaged = (params: {
        $count?: boolean;
        $top: number;
        $skip: number | undefined;
        profileType: ProfileType;
        profileId?: string;
        profileName?: string;
        companyId?: string;
        companyName?: string;
    }): ApiResponse<PageResult<ProfileViewModel>> => api().get(`${this.route}/SearchPaged/`, { params });
}
