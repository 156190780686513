import { Apis, ViewModels } from '../../../../services/backendServices';
import {
    ParsedInputParameter,
    ParsedSettingValueType,
    ParsedXrefParameters,
    processXrefParameters,
} from '../../../CustomJSRulesV2/Utils/processXrefParameters';
import { ScriptCompiler } from '../../Utility';
import { RuntimeRule } from './RuntimeRule';
import { api } from '../../../../services/api';
import { createRulesFilter } from './RuntimeRuleFetcher.filter';
import { orderBy } from '../../../../services/utils/orderBy';
import { ascendingOrderByKey } from '../../../../utils/sorting';
import { dummyScript, dummyScriptName } from './dummyCustomJSRuleScript';

export function getTestScriptForDevelopment(src?: string) {
    if (src !== dummyScriptName || !dummyScriptName.length) {
        return null;
    }

    return dummyScript;
}

function createConfigParamsMap(xref: ParsedXrefParameters, vendorClass?: string) {
    const existingParams = new Set<string>();
    const configParams = new Map<string, ParsedSettingValueType>();

    xref.ruleParameters.forEach((ruleParam) => {
        existingParams.add(ruleParam.Name);

        if (ruleParam.DefaultValue !== undefined) {
            configParams.set(ruleParam.Name, ruleParam.DefaultValue);
        }
    });

    const performOverride = (params: ParsedInputParameter[]) => {
        params.forEach((param) => {
            // params could exist in the xref input parameters or input parameter override input parameters
            // that don't exist in the rule's param list. this prevents those from appearing in the config
            // param map.
            if (existingParams.has(param.Name)) {
                if (param.Enabled) {
                    configParams.set(param.Name, param.Value);
                } else {
                    configParams.delete(param.Name);
                }
            }
        });
    };

    performOverride(xref.xrefParameterValues);

    const defaultOverride = xref.parameterOverrides.find((o) => !o.vendorClasses.length);

    /**
     * When the document has no vendorClass,
     * we'll use the default override if it's available.
     */
    const vendorClassOverride =
        vendorClass === undefined
            ? defaultOverride
            : xref.parameterOverrides.find((o) => o.vendorClasses.includes(vendorClass)) ?? defaultOverride;

    if (vendorClassOverride) {
        performOverride(vendorClassOverride.parameterValues);
    }

    return configParams;
}

async function fetchAndCompileScript(src: string) {
    const { data: scriptSrc } = await api().get(`../TransceptaAPICode/BuyerCustomJSFiles/${src}`);

    return ScriptCompiler.compileScript(getTestScriptForDevelopment(src) ?? scriptSrc);
}

async function createRule(
    xref: ViewModels.BuyerCustomJSRuleMappingXrefViewModel,
    vendorClass?: string
): Promise<RuntimeRule> {
    if (!xref.BuyerCustomJSRule) {
        throw new Error('Expected BuyerCustomJSRule to exist.');
    }

    const configParams = createConfigParamsMap(processXrefParameters(xref), vendorClass);
    const script = await fetchAndCompileScript(xref.BuyerCustomJSRule.JavaScriptFileName);

    return {
        name: xref.BuyerCustomJSRule.Name,
        script,
        configParams,
    };
}

function filterBuyerCustomJSRuleMappingsByVendorClass(
    ruleMappings: ViewModels.BuyerCustomJSRuleMappingViewModel[],
    vendorClass: string | undefined
) {
    return ruleMappings.filter(
        (mapping) => mapping.VendorClasses == null || mapping.VendorClasses?.split(',').some((vc) => vc === vendorClass)
    );
}

export class RuntimeRuleFetcher {
    private api = new Apis.BuyerCustomJSRuleMappingApi();

    async fetchRules(
        params: Apis.GetBuyerCustomJSRulesMappingByExecutionContextParams & {
            vendorClass?: string;
        },
        configurationRetriever?: (mapping: ViewModels.BuyerCustomJSRuleMappingViewModel) => void,
        filterFn?: (mappings: ViewModels.BuyerCustomJSRuleMappingViewModel) => boolean
    ) {
        const { vendorClass, ...rest } = params;

        const $filter = createRulesFilter({ vendorClass });

        const response = await this.api.getBuyerCustomJSRulesMappingByExecutionContext({
            ...rest,
            $filter,
            $orderby: orderBy([{ field: 'BuyerCompanyId', sort: 'desc' }]),
        });

        /**
         * The $filter created by createRulesFilter returns more results than it should because it uses `contains()` instead of `eq()`.
         * It was decided that we should add filtering logic programmatically instead of modifying the $filter string.
         */
        const mappingsFilteredByVendorClass = filterBuyerCustomJSRuleMappingsByVendorClass(response.data, vendorClass);

        const mappings =
            filterFn != null ? mappingsFilteredByVendorClass.filter(filterFn) : mappingsFilteredByVendorClass;

        if (mappings.length === 0) {
            return [];
        }

        const mapping = mappings[0];

        configurationRetriever?.(mapping);

        return Promise.all(
            mapping.BuyerCustomJSRuleMappingXrefs.sort(ascendingOrderByKey('Ordinal')).map((x) =>
                createRule(x, vendorClass)
            )
        );
    }
}
