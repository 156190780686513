import { default as ValidationFailureTypesApi } from '../../../../services/ValidationFailureTypes/Api/ValidationFailureTypesApi';
import { BusinessDocType } from '../../../../types';
import { ValidationFailureDictionary } from '../../../DocumentEditRules/types/ValidationFailureType';

export async function fetchValidationFailureTypes(buyerCompanyId: number, businessDocType: BusinessDocType) {
    const vftApi = new ValidationFailureTypesApi();

    const res = await vftApi.getValidationFailureTypesByCompanyId(
        buyerCompanyId,
        `BusinessDocType eq ${businessDocType}`
    );

    const failureTypeDictionary: ValidationFailureDictionary = {};

    res.data.forEach((v) => {
        failureTypeDictionary[v.ErrorCode] = {
            errorCode: v.ErrorCode,
            titleText: v.TitleText,
            detailText: v.DetailText,
            disposition: v.Disposition,
            id: v.Id,
            validationType: v.ValidationType,
        };
    });

    return failureTypeDictionary;
}
