import { SeriesPieOptions } from 'highcharts';
import { DashboardApi, TopSupplierRejections } from '../../../Api';
import { DashboardDataPayload } from '../../../Api/DashboardDataPayload';
import { createDashboardReportService } from '../../createDashboardReportService';
import { DashboardReportService } from '../../DashboardReportService';

class SuppliersWithMostInvoiceValidationFailuresReportService
    implements DashboardReportService<TopSupplierRejections, SeriesPieOptions['data']>
{
    private api = new DashboardApi();

    get storedProcedure() {
        return 'TopSupplierRejectionsDashboardReport' as const;
    }

    async fetchReport(settings: DashboardDataPayload) {
        const res = await this.api.getDashboardReportData<TopSupplierRejections>(settings, this.storedProcedure);
        return res.data;
    }

    get nullifyDataIfEmptyObj() {
        return {
            optIn: true,
            xAxisPropertyKey: undefined,
        };
    }

    transformVMToChartData(vm: TopSupplierRejections['viewModel']) {
        return vm.map((p) => ({
            y: p.NumRejections,
            name: p.SupplierName,
            label: p.SupplierName,
        }));
    }

    async exportCSV(settings: DashboardDataPayload) {
        return this.api.exportDashboardReport(settings, 'TopSupplierRejectionsDashboardExportReport');
    }
}

export const useSuppliersWithMostInvoiceValidationFailuresReportService = createDashboardReportService(
    SuppliersWithMostInvoiceValidationFailuresReportService
);
