import { APIPermission, IUserRoleViewModel, RoleType } from '../Api';
import { Permission } from './types';

/**
 * Permissions that are assigned to every logged in user
 */
const defaultPermissions: Permission[] = [];

/**
 * Permissions assigned based on having a certain role
 */
const rolePermissionMapping: { [key in RoleType]: Permission[] } = {
    [RoleType.SuperUserRights]: [],
    [RoleType.SuperUserApprover]: ['WorkflowTask.ActOnBehalfOfAssignedUser'],
    [RoleType.CompanyAdminRights]: [
        'Profile.ViewProfile',
        'Profile.ListProfile',
        'AccountProfile.ViewProfile',
        'AccountProfile.ListProfile',
        'AccountProfile.EditProfile',
        'ManageUserSettings.CreateUser',
        'ManageUserSettings.EditUser',
        'ManageUserSettings.DeleteUser',
        'ManageUserSettings.ViewUser',
        'ManageUserSettings.ListUsers',
        'ManageSIMDocuments.CreateDocument',
        'ManageSIMDocuments.ListDocument',
        'ManageSIMDocuments.ViewDocument',
        'ManageSIMDocuments.EditDocument',
        'DocumentSIMType.ListProfile',
        'DocumentSIMType.ViewProfile',
        'DocumentSIMType.EditProfile',
        'DocumentSIMType.CreateProfile',
        'InvoiceProfile.ViewProfile',
        'InvoiceProfile.EditProfile',
        'InvoiceProfile.CreateProfile',
        'BuyerPortal.SignIn',
        'SupplierPortal.SignIn',
    ],
    // based on Supplier Settings page logic, SupplierAdminRights should have Invoice Profile permissions:
    // manageInvoiceProfiles: (isAccountAdmin || isSupplierAdmin) && !isLimitedToParkingLotOnly,
    // manageSalesProfiles: (isAccountAdmin || isSupplierAdmin) && !isLimitedToParkingLotOnly,
    [RoleType.SupplierAdminRights]: [
        'InvoiceProfile.ViewProfile',
        'InvoiceProfile.EditProfile',
        'InvoiceProfile.CreateProfile',
        'SupplierPortal.SignIn',
    ],
    [RoleType.InvoiceEditRights]: [
        'InvoiceProfile.ViewProfile',
        'InvoiceProfile.EditProfile',
        'InvoiceProfile.CreateProfile',
        'SupplierPortal.SignIn',
    ],
    [RoleType.SupplierLoginRights]: ['SupplierPortal.SignIn'],
    [RoleType.BuyerAdminRights]: [
        'Profile.ViewProfile',
        'Profile.ListProfile',
        'AccountProfile.ViewProfile',
        'AccountProfile.ListProfile',
        'DocumentSIMType.ListProfile',
        'DocumentSIMType.ViewProfile',
        'BuyerPortal.SignIn',
    ],
    [RoleType.PaymentCreationRights]: [],
    [RoleType.PaymentApprovalRights]: [],
    [RoleType.BuyerLoginRights]: [
        'VendorManagement.ListVendors',
        'VendorManagement.ViewVendor',
        'VendorManagement.ListVendorSites',
        'VendorManagement.ViewVendorSite',
        'ManageUserSettings.CreateUserGroup',
        'ManageUserSettings.EditUserGroup',
        'ManageUserSettings.DeleteUserGroup',
        'ManageUserSettings.ViewUserGroup',
        'ManageUserSettings.ListUserGroups',
        'BuyerPortal.SignIn',
    ],
    [RoleType.DynamicDiscountingEditRights]: [],
    [RoleType.DynamicDiscountingAdminRights]: [],
    [RoleType.DynamicDiscountingViewRights]: [],
    [RoleType.ParkingLotAdminRights]: [],
    [RoleType.ParkingLotOnly]: [],
    [RoleType.SupplierCreationRights]: [
        'VendorManagement.CreateVendor',
        'VendorManagement.EditVendor',
        'VendorManagement.CreateVendorSite',
        'VendorManagement.EditVendorSite',
    ],
};

/**
 * Permissions assigned based on having an API Permission
 */
const APIPermissionToLocalPermissionMapping: Record<APIPermission, Permission[]> = {
    VIEW_CONFIGURABLE_JSRULES_MAPPING: ['BuyerCustomJSRule.ListBuyerCustomJSRuleMapping'],
    CREATE_UPDATE_DELETE_CONFIGURABLE_JSRULES_MAPPING: [
        'BuyerCustomJSRule.ViewBuyerCustomJSRuleMapping',
        'BuyerCustomJSRule.CreateBuyerCustomJSRuleMapping',
        'BuyerCustomJSRule.EditBuyerCustomJSRuleMapping',
        'BuyerCustomJSRule.DeleteBuyerCustomJSRuleMapping',
    ],
    RUN_DASHBOARD_REPORT: ['Dashboard.DisplayNewDashboard'],
    CREATE_WORKFLOW_DOCUMENT: ['BuyerPortal.InvoiceCreation'],
};

/**
 * Assign permissions using business rules that are too complex to encode in the mapping above (these
 * would be the user having a combination of certain roles and lacking others).
 * @param _permissions user's permission set that is being built
 * @param _roles roles the user has
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function assignPermissionsWithBusinessRules(permissions: Set<Permission>, roles: Set<RoleType>) {}

export function getPermissions(userRoles?: IUserRoleViewModel[], userPermissions?: readonly APIPermission[]) {
    const permissions = new Set<Permission>(defaultPermissions);

    if (!userRoles && !userPermissions) {
        return permissions;
    }

    // assign permissions using the simple table mapping
    userRoles?.forEach((userRole) => {
        const roleType: RoleType = userRole.RoleID;

        /**
         * Roles are being created dynamically now, so there will be roles we don't know of
         * and rolePermissionMapping[roleType] could be undefined. In those cases
         * all we should care about is the api permissions the user has.
         */
        const rolePermissions = rolePermissionMapping[roleType] ?? [];

        rolePermissions.forEach((permission) => {
            permissions.add(permission);
        });
    });

    // assign permissions using combinations of roles
    const roleSet = new Set<RoleType>(userRoles?.map((x) => x.RoleID) ?? []);
    assignPermissionsWithBusinessRules(permissions, roleSet);

    // assign local permissions based on api permissions
    userPermissions?.forEach((apiPermission) =>
        (APIPermissionToLocalPermissionMapping[apiPermission] ?? []).forEach((localPermission) => {
            permissions.add(localPermission);
        })
    );

    return permissions;
}
